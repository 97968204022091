import { formatNumber } from '@ydistri/utils';

export const formatValueOrQuantity = (valueOrQuantity: number): string => {
  if (isNaN(Number(valueOrQuantity))) {
    return 'N/A';
  }

  if (valueOrQuantity >= 1000000 || valueOrQuantity <= -1000000) {
    const withSeparator = formatNumber(valueOrQuantity / 1000000, 1);
    return `${withSeparator.replace('.0', '')}M`;
  }

  if (valueOrQuantity >= 1000 || valueOrQuantity <= -1000) {
    return `${(valueOrQuantity / 1000).toFixed(1).replace('.0', '')}k`;
  }
  return valueOrQuantity.toFixed(1).replace('.0', '');
};
