const validatePassword = (p: string, minLength = 8): true | string => {
  const upperCaseLetters = /[A-Z]/g;
  const lowerCaseLetters = /[a-z]/g;
  const numericCharacters = /[0-9]/g;
  const specialCharacters = /[-@_~?!,.^$#%&*()]/g;

  if (p.length < minLength) return `Minimal length is ${minLength}`;
  if (!p.match(upperCaseLetters)) return `Does not contain uppercase letter!`;
  if (!p.match(lowerCaseLetters)) return `Does not contain lowercase letter!`;
  if (!p.match(numericCharacters)) return `Does not contain numeric character!`;
  if (!p.match(specialCharacters)) return `Does not contain special character!`;
  return true;
};

export default validatePassword;
