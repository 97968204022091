/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import { ApplicationDataResponseCollectionResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';
export class ApplicationData<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags ApplicationData
   * @name ApplicationDataList
   * @request GET:/ApplicationData
   * @response `200` `ApplicationDataResponseCollectionResponse` OK
   */
  applicationDataList = (params: RequestParams = {}) =>
    this.http.request<ApplicationDataResponseCollectionResponse, any>({
      path: `/ApplicationData`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
