import { styled } from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';
import React from 'react';
import { computeRemSize } from '../styles/style';

interface StyledSpinnerProps {
  /** color of the spinning wheel */
  color?: string;
}

const StyledSpinner = styled(AiOutlineLoading)<StyledSpinnerProps>`
  color: ${({ color }) => color ?? 'inherit'};
  margin-right: ${computeRemSize(5)};
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
`;

/**
 * Animated spinning wheel to indicate loading
 * @param color Color of the spinning wheel
 * @constructor
 */
export const Spinner: React.FC<StyledSpinnerProps> = ({ color }) => {
  return <StyledSpinner color={color} />;
};
