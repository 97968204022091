import React from 'react';
import { Text, Title, logo } from '@ydistri/ds';
import { ASPBackground, ASPLogo, ASPShadowBox } from './applicationStatusPageComponents';

export const ApplicationStatusUpgradingPage: React.FC = () => {
  return (
    <ASPBackground>
      <ASPShadowBox>
        <ASPLogo src={logo} />
        <Title $size="large">Application is being upgraded.</Title>
        <Text>Sorry for the inconvenience, please try again later.</Text>
      </ASPShadowBox>
    </ASPBackground>
  );
};
