import { styled, css } from 'styled-components';
import { COLORS } from '@ydistri/ds';
import React, { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { BarLineSizes, BarLineTypes } from './BarLine';
import { Popover } from 'antd';

interface StyledBarLinePartitionProps {
  $type: BarLineTypes;
  $size: BarLineSizes;
  $color: string;
  $percentage: number;
  $contentAlignment?: CSSProperties['alignItems'];
  $border?: boolean;
}

export const StyledBarLinePartition = styled.div<StyledBarLinePartitionProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${p => p.$contentAlignment ?? 'center'};

  background-color: ${p => p.$color};

  ${p =>
    p.$type === BarLineTypes.HORIZONTAL &&
    css`
      ${p.$border && `border-inline-end: 1px solid ${COLORS.BORDER};`}
    `}

  ${p =>
    p.$type === BarLineTypes.VERTICAL &&
    css`
      ${p.$border && `border-bottom: 1px solid ${COLORS.BORDER};`}
    `}
`;

export const StyledHighlightedBarLinePartition = styled.div<{
  $highlightedPercentage: number;
  $type: BarLineTypes;
}>`
  position: absolute;

  background: repeating-linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 8px,
    transparent 8px,
    transparent 16px
  );
  z-index: 2;

  ${p =>
    p.$type === BarLineTypes.HORIZONTAL &&
    css`
      top: 0;
      right: 0;
      height: 100%;
      width: ${p.$highlightedPercentage}%;
      border-inline-start: 1px solid rgba(0, 0, 0, 0.05);
    `}

  ${p =>
    p.$type === BarLineTypes.VERTICAL &&
    css`
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${p.$highlightedPercentage}%;
      border-top: 1px solid red;
    `}
`;

export const PopoverTrigger = styled.div<Pick<StyledBarLinePartitionProps, '$contentAlignment'>>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${p => p.$contentAlignment ?? 'center'};
`;

export const StyledBarLinePartitionWrapper = styled.div<StyledBarLinePartitionProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.25rem;

  ${p =>
    p.$type === BarLineTypes.HORIZONTAL &&
    css`
      width: ${p.$percentage}%;
      height: 100%;
      min-height: 100%;

      &:first-child ${StyledBarLinePartition} {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
      }

      &:last-child ${StyledBarLinePartition} {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    `}

  ${p =>
    p.$type === BarLineTypes.VERTICAL &&
    css`
      width: 100%;
      height: ${p.$percentage}%;
      max-height: ${p.$percentage}%;

      &:first-child ${StyledBarLinePartition} {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child ${StyledBarLinePartition} {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    `}
`;

export interface BarLinePartitionProps {
  type?: BarLineTypes;
  size?: BarLineSizes;
  color?: string;
  percentage: number;
  highlightedPercentage?: number;
  border?: boolean;
  content?: ReactNode;
  contentAlignment?: CSSProperties['alignItems'];
  minSizeToDisplayContent?: number;
  contentUnder?: ReactNode;
  tooltip?: ReactNode;
}

/**
 * This component is used to create a segment of a BarLine.
 * It is used internally by the BarLine component and should not be used in
 * isolation.
 * @param type Vertical or Horizontal
 * @param size
 * @param color
 * @param percentage
 * @param highlightedPercentage
 * @param border
 * @param content
 * @param contentAlignment
 * @param minSizeToDisplayContent
 * @param contentUnder
 * @param tooltip
 * @constructor
 */
const BarLinePartition: React.FC<BarLinePartitionProps> = ({
  type = BarLineTypes.HORIZONTAL,
  size = BarLineSizes.MEDIUM,
  color = 'transparent',
  percentage,
  highlightedPercentage = 0,
  border = false,
  content,
  contentAlignment,
  minSizeToDisplayContent = 0,
  contentUnder,
  tooltip,
}) => {
  const [sizeToCompare, setSizeToCompare] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const observeResize = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (entries.length > 0) {
          setSizeToCompare(
            entries[0].contentRect[type === BarLineTypes.HORIZONTAL ? 'width' : 'height'],
          );
        }
      }),
    [type],
  );

  useEffect(() => {
    const divElement = ref.current;

    if (divElement) {
      observeResize.observe(divElement);
    }
    return () => {
      if (divElement) {
        observeResize.unobserve(divElement);
      }
    };
  }, [observeResize, type]);

  const displayContent = minSizeToDisplayContent <= sizeToCompare;

  const tooltipPlacement = type === BarLineTypes.HORIZONTAL ? 'top' : 'right';

  return (
    <StyledBarLinePartitionWrapper
      $type={type}
      ref={ref}
      $color={color}
      $percentage={percentage}
      $border={border}
      $size={size}
    >
      <StyledBarLinePartition
        $type={type}
        $color={color}
        $percentage={percentage}
        $border={border}
        $size={size}
        $contentAlignment={contentAlignment}
      >
        <Popover content={tooltip ?? content} placement={tooltipPlacement}>
          <PopoverTrigger $contentAlignment={contentAlignment}>
            {highlightedPercentage > 0 && (
              <StyledHighlightedBarLinePartition
                $type={type}
                $highlightedPercentage={highlightedPercentage}
              />
            )}
            {displayContent && (content ?? null)}
          </PopoverTrigger>
        </Popover>
      </StyledBarLinePartition>
      {contentUnder ?? null}
    </StyledBarLinePartitionWrapper>
  );
};

export default BarLinePartition;
