import { DefaultOptionType } from 'rc-select/lib/Select';

const removeDiacritics = (text: string): string => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export interface SearchableSelectOptionType extends DefaultOptionType {
  searchableString: string;
}

export const selectFilterHandler = <T extends DefaultOptionType>(
  input: string,
  option?: T,
  includeValue: boolean = true,
  diacritics: boolean = true,
): boolean => {
  let searchFor = input.toLowerCase();
  let optionValue = option?.value?.toString().toLowerCase() ?? '';
  let optionLabel = option?.label?.toString().toLowerCase() ?? '';

  if (!diacritics) {
    searchFor = removeDiacritics(searchFor);
    optionValue = removeDiacritics(optionValue);
    optionLabel = removeDiacritics(optionLabel);
  }

  let inSearchableString = false;
  if (option && 'searchableString' in option && typeof option.searchableString === 'string') {
    let searchableString = option.searchableString.toLowerCase();
    if (!diacritics) {
      searchableString = removeDiacritics(searchableString);
    }
    inSearchableString = searchableString.includes(searchFor);
  }

  return (
    (includeValue && optionValue.includes(searchFor)) ||
    optionLabel.includes(searchFor) ||
    inSearchableString
  );
};
