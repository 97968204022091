import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { COLORS, computeRemSize } from '@ydistri/ds';

const AREA_HEIGHT = 0.25;
const TOP_LINE_SIZE = 1;
const BOTTOM_LINE_SIZE = 2.5;

interface BarLineConnectionProps {
  $widthTop: CSSProperties['width'];
  $widthBottom: CSSProperties['width'];
}

const BarLineConnectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${AREA_HEIGHT}rem;
  margin-bottom: 1rem;
`;

const BarLineConnectionTopArea = styled.div<Pick<BarLineConnectionProps, '$widthTop'>>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${AREA_HEIGHT}rem;
  margin-bottom: 1rem;
  width: ${p => p.$widthTop};
  min-width: ${p => p.$widthTop};

  border-left: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-right: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER};
`;

const BarLineConnectionBottomArea = styled.div<Pick<BarLineConnectionProps, '$widthBottom'>>`
  position: absolute;
  top: calc(
    ${AREA_HEIGHT}rem + ${TOP_LINE_SIZE}rem + ${BOTTOM_LINE_SIZE}rem + ${computeRemSize(1)}
  );
  right: 0;
  height: ${AREA_HEIGHT}rem;
  width: ${p => p.$widthBottom};
  min-width: ${p => p.$widthBottom};

  border-left: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-right: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-top: ${computeRemSize(1)} solid ${COLORS.BORDER};
`;

const BarLineConnectionLineFromTop = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(${AREA_HEIGHT}rem);
  height: ${TOP_LINE_SIZE}rem;
  border-left: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER};
`;

const BarLineConnectionLineHorizontal = styled.div<BarLineConnectionProps>`
  position: absolute;
  top: calc(${TOP_LINE_SIZE}rem + ${AREA_HEIGHT}rem + ${computeRemSize(1)});
  left: calc(${p => p.$widthTop} / 2 + ${computeRemSize(1)});
  height: ${computeRemSize(1)};
  width: ${p =>
    `calc(100% - (${p.$widthTop ?? 0} / 2) - (${p.$widthBottom ?? 0} / 2) - ${computeRemSize(1)})`};
  background-color: ${COLORS.BORDER};
`;

const BarLineConnectionLineFromBottom = styled.div<Pick<BarLineConnectionProps, '$widthBottom'>>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-${BOTTOM_LINE_SIZE}rem);
  height: calc(${BOTTOM_LINE_SIZE}rem - ${computeRemSize(1)});
  width: ${computeRemSize(1)};
  background-color: ${COLORS.BORDER};
`;

/**
 * Draws a line connecting a segment in one BarLine component to another BarLine component below.
 * @param widthTop The width of the segment in the BarLine component above.
 * @param widthBottom The width of the  BarLine component below.
 * @constructor
 */
export const BarLineConnection: React.FC<BarLineConnectionProps> = ({
  $widthTop,
  $widthBottom,
}) => {
  return (
    <BarLineConnectionWrapper>
      <BarLineConnectionTopArea $widthTop={$widthTop}>
        <BarLineConnectionLineFromTop />
      </BarLineConnectionTopArea>

      <BarLineConnectionLineHorizontal $widthTop={$widthTop} $widthBottom={$widthBottom} />

      <BarLineConnectionBottomArea $widthBottom={$widthBottom}>
        <BarLineConnectionLineFromBottom $widthBottom={$widthBottom} />
      </BarLineConnectionBottomArea>
    </BarLineConnectionWrapper>
  );
};
