/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  CalculationExperimentalConfigurationRequest,
  CalculationExperimentalConfigurationResponseCollectionResponse,
  Condition,
  ConfigurationRuleCreateRequest,
  ConfigurationRuleReplaceRequest,
  ConfigurationRuleResponseCollectionResponse,
  ConfigurationRuleResponseDataResponse,
  ConfigurationRuleSetupCreateRequest,
  ConfigurationRuleSetupDeleteRequest,
  ConfigurationRuleSetupUpdateRequest,
  ConfigurationRuleUpdateRequest,
  DepartmentResponseCollectionResponse,
  RegionResponseCollectionResponse,
  ScopeEntityConfigurationResponseCollectionResponse,
  Sorting,
  StoreResponseCollectionResponse,
  StoreTypeResponseCollectionResponse,
  TargetListResponseCollectionResponse,
  TargetListResponseDataResponse,
  TemplateRequest,
  TemplateResponseCollectionResponse,
  TemplateResponseDataResponse,
  TemplateUpdateRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class Templates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplatesTargetLists
   * @request GET:/Templates/{templateId}/TargetLists
   * @response `200` `TargetListResponseCollectionResponse` OK
   */
  getTemplatesTargetLists = (templateId: number, params: RequestParams = {}) =>
    this.http.request<TargetListResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/TargetLists`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PostTemplatesTargetList
   * @request POST:/Templates/{templateId}/TargetLists/{id}
   * @response `200` `TargetListResponseDataResponse` OK
   */
  postTemplatesTargetList = (templateId: number, id: number, params: RequestParams = {}) =>
    this.http.request<TargetListResponseDataResponse, any>({
      path: `/Templates/${templateId}/TargetLists/${id}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PatchTemplatesTargetList
   * @request PATCH:/Templates/{templateId}/TargetLists/{id}
   * @response `200` `TargetListResponseDataResponse` OK
   */
  patchTemplatesTargetList = (templateId: number, id: number, params: RequestParams = {}) =>
    this.http.request<TargetListResponseDataResponse, any>({
      path: `/Templates/${templateId}/TargetLists/${id}`,
      method: 'PATCH',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name DeleteTemplatesTargetList
   * @request DELETE:/Templates/{templateId}/TargetLists/{id}
   * @response `200` `void` OK
   */
  deleteTemplatesTargetList = (templateId: number, id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Templates/${templateId}/TargetLists/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplates
   * @request GET:/Templates
   * @response `200` `TemplateResponseCollectionResponse` OK
   */
  getTemplates = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TemplateResponseCollectionResponse, any>({
      path: `/Templates`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PostTemplate
   * @request POST:/Templates
   * @response `200` `TemplateResponseDataResponse` OK
   */
  postTemplate = (data: TemplateRequest, params: RequestParams = {}) =>
    this.http.request<TemplateResponseDataResponse, any>({
      path: `/Templates`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplate
   * @request GET:/Templates/{id}
   * @response `200` `TemplateResponseDataResponse` OK
   */
  getTemplate = (id: number, params: RequestParams = {}) =>
    this.http.request<TemplateResponseDataResponse, any>({
      path: `/Templates/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PatchTemplate
   * @request PATCH:/Templates/{id}
   * @response `200` `TemplateResponseDataResponse` OK
   */
  patchTemplate = (id: number, data: TemplateUpdateRequest, params: RequestParams = {}) =>
    this.http.request<TemplateResponseDataResponse, any>({
      path: `/Templates/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name DeleteTemplate
   * @request DELETE:/Templates/{id}
   * @response `200` `void` OK
   */
  deleteTemplate = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Templates/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateExperimentalConfigurations
   * @request GET:/Templates/{templateId}/ExperimentalConfigurations
   * @response `200` `CalculationExperimentalConfigurationResponseCollectionResponse` OK
   */
  getTemplateExperimentalConfigurations = (templateId: number, params: RequestParams = {}) =>
    this.http.request<CalculationExperimentalConfigurationResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/ExperimentalConfigurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PatchTemplateExperimentalConfigurations
   * @request PATCH:/Templates/{templateId}/ExperimentalConfigurations
   * @response `200` `CalculationExperimentalConfigurationResponseCollectionResponse` OK
   */
  patchTemplateExperimentalConfigurations = (
    templateId: number,
    data: CalculationExperimentalConfigurationRequest[],
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationExperimentalConfigurationResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/ExperimentalConfigurations`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateConfigurationRules
   * @request GET:/Templates/{templateId}/ConfigurationRules
   * @response `200` `ConfigurationRuleResponseCollectionResponse` OK
   */
  getTemplateConfigurationRules = (templateId: number, params: RequestParams = {}) =>
    this.http.request<ConfigurationRuleResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PostTemplateConfigurationRule
   * @request POST:/Templates/{templateId}/ConfigurationRules
   * @response `200` `ConfigurationRuleResponseDataResponse` OK
   */
  postTemplateConfigurationRule = (
    templateId: number,
    data: ConfigurationRuleCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ConfigurationRuleResponseDataResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name DeleteTemplateConfigurationRules
   * @request DELETE:/Templates/{templateId}/ConfigurationRules
   * @response `200` `void` OK
   */
  deleteTemplateConfigurationRules = (templateId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Templates/${templateId}/ConfigurationRules`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateScopeEntityConfigurations
   * @request GET:/Templates/{templateId}/ConfigurationRules/ScopeEntityConfigurations
   * @response `200` `ScopeEntityConfigurationResponseCollectionResponse` OK
   */
  getTemplateScopeEntityConfigurations = (templateId: number, params: RequestParams = {}) =>
    this.http.request<ScopeEntityConfigurationResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules/ScopeEntityConfigurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateConfigurationRule
   * @request GET:/Templates/{templateId}/ConfigurationRules/{id}
   * @response `200` `ConfigurationRuleResponseDataResponse` OK
   */
  getTemplateConfigurationRule = (templateId: number, id: number, params: RequestParams = {}) =>
    this.http.request<ConfigurationRuleResponseDataResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PutTemplateConfigurationRule
   * @request PUT:/Templates/{templateId}/ConfigurationRules/{id}
   * @response `200` `ConfigurationRuleResponseDataResponse` OK
   */
  putTemplateConfigurationRule = (
    templateId: number,
    id: number,
    data: ConfigurationRuleReplaceRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ConfigurationRuleResponseDataResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PatchTemplateConfigurationRule
   * @request PATCH:/Templates/{templateId}/ConfigurationRules/{id}
   * @response `200` `void` OK
   */
  patchTemplateConfigurationRule = (
    templateId: number,
    id: number,
    data: ConfigurationRuleUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name DeleteTemplateConfigurationRule
   * @request DELETE:/Templates/{templateId}/ConfigurationRules/{id}
   * @response `200` `void` OK
   */
  deleteTemplateConfigurationRule = (templateId: number, id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PostTemplateConfigurationRuleSetup
   * @request POST:/Templates/{templateId}/ConfigurationRules/{id}/Setups
   * @response `200` `ConfigurationRuleResponseDataResponse` OK
   */
  postTemplateConfigurationRuleSetup = (
    templateId: number,
    id: number,
    data: ConfigurationRuleSetupCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ConfigurationRuleResponseDataResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}/Setups`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name PatchTemplateConfigurationRuleSetup
   * @request PATCH:/Templates/{templateId}/ConfigurationRules/{id}/Setups
   * @response `200` `ConfigurationRuleResponseDataResponse` OK
   */
  patchTemplateConfigurationRuleSetup = (
    templateId: number,
    id: number,
    data: ConfigurationRuleSetupUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ConfigurationRuleResponseDataResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}/Setups`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name DeleteTemplateConfigurationRuleSetup
   * @request DELETE:/Templates/{templateId}/ConfigurationRules/{id}/Setups
   * @response `200` `ConfigurationRuleResponseDataResponse` OK
   */
  deleteTemplateConfigurationRuleSetup = (
    templateId: number,
    id: number,
    data: ConfigurationRuleSetupDeleteRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ConfigurationRuleResponseDataResponse, any>({
      path: `/Templates/${templateId}/ConfigurationRules/${id}/Setups`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateStores
   * @request GET:/Templates/{templateId}/Stores
   * @response `200` `StoreResponseCollectionResponse` OK
   */
  getTemplateStores = (
    templateId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<StoreResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/Stores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateStoreTypes
   * @request GET:/Templates/{templateId}/StoreTypes
   * @response `200` `StoreTypeResponseCollectionResponse` OK
   */
  getTemplateStoreTypes = (
    templateId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<StoreTypeResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/StoreTypes`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateDepartments
   * @request GET:/Templates/{templateId}/Departments
   * @response `200` `DepartmentResponseCollectionResponse` OK
   */
  getTemplateDepartments = (
    templateId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DepartmentResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/Departments`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Templates
   * @name GetTemplateRegions
   * @request GET:/Templates/{templateId}/Regions
   * @response `200` `RegionResponseCollectionResponse` OK
   */
  getTemplateRegions = (
    templateId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RegionResponseCollectionResponse, any>({
      path: `/Templates/${templateId}/Regions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
