import { Popover } from 'antd';
import { COLORS, Panel, Text } from '@ydistri/ds';
import React from 'react';
import { styled } from 'styled-components';

const OutlierPanel = styled(Panel)<{ $center?: boolean }>`
  background-color: ${COLORS.TABLE_HIGHLIGHT_RED};
  border: 1px solid ${COLORS.DANGER};
  color: ${COLORS.DANGER};
  ${p =>
    p.$center ? `justify-content: center;` : `justify-content: flex-end; padding-right: 0.25rem;`}
`;

interface OutlierValueProps {
  value: number | string;
  tooltip?: string;
  center?: boolean;
}

export const OutlierValue: React.FC<OutlierValueProps> = ({ value, tooltip, center }) => {
  if (tooltip) {
    return (
      <Popover content={tooltip} trigger="hover">
        <OutlierPanel $center={center}>
          <Text $bold={true}>{value}</Text>
        </OutlierPanel>
      </Popover>
    );
  }

  return (
    <OutlierPanel $center={center}>
      <Text $bold={true}>{value}</Text>
    </OutlierPanel>
  );
};
