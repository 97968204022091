import { Popover, Space, Switch } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { styled } from 'styled-components';
import { Label } from '../typography/Label/Label';
import { TooltipPlacement } from 'antd/lib/tooltip';

export enum LabelPlacement {
  LEFT,
  RIGHT,
}

interface ToggleWrapperProps {
  $margin?: string;
}

export interface ToggleProps {
  /** ID of the element */
  id?: string;
  /** Is the toggle switch on or off */
  checked: boolean;
  /** Is the control disabled */
  disabled?: boolean;
  /** Callback called when either the switch or the label are clicked */
  onClick?: () => void;
  /** Position of the label in relation to the toggle */
  labelPlacement?: LabelPlacement;
  margin?: string;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  decoration?: React.ReactNode;
}

export interface LabeledToggleProps extends ToggleProps {
  /** Text to be displayed together with the switch */
  label: string;
}

interface ToggleLabelProps {
  /** Position of the label in relation to the toggle */
  $labelPlacement: LabelPlacement;
}

const ToggleWrapper = styled.div<ToggleWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 9pt;
  margin: ${p => p.$margin ?? '4px'};
`;

const ToggleLabel = styled(Label)<ToggleLabelProps>`
  cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  display: inline-block;
`;

const DecorationWrapper = styled.span`
  cursor: pointer;
`;

export const LabeledToggle: React.FC<LabeledToggleProps> = ({
  label,
  id,
  checked,
  disabled,
  onClick,
  margin,
  labelPlacement = LabelPlacement.RIGHT,
  tooltip,
  decoration,
  tooltipPlacement = 'left',
  ...rest
}) => {
  const labelComponent = useMemo(
    () => (
      <ToggleLabel
        onClick={!disabled ? onClick : undefined}
        data-type="ToggleLabel"
        $labelPlacement={labelPlacement}
      >
        {label}
      </ToggleLabel>
    ),
    [label, disabled, onClick, labelPlacement],
  );

  const toggleComponent = useMemo(
    () => (
      <Switch
        id={id}
        checked={checked}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        data-type="Toggle"
        data-checked={checked}
      />
    ),
    [checked, disabled, onClick, id],
  );

  const decorationComponent = useMemo(() => {
    if (decoration) {
      return (
        <DecorationWrapper onClick={!disabled ? onClick : undefined}>
          {decoration}
        </DecorationWrapper>
      );
    }
  }, [decoration, disabled, onClick]);

  /**
   * Renders components based on the label placement attribute
   */
  const body = useMemo(() => {
    switch (labelPlacement) {
      case LabelPlacement.LEFT:
        return (
          <>
            {labelComponent}
            {toggleComponent}
            {decorationComponent}
          </>
        );
      case LabelPlacement.RIGHT:
        return (
          <>
            {decorationComponent}
            {toggleComponent}
            {labelComponent}
          </>
        );
    }
  }, [labelPlacement, labelComponent, toggleComponent, decorationComponent]);

  const renderBody = useCallback(() => {
    return (
      <ToggleWrapper $margin={margin} data-type="LabeledToggle" {...rest}>
        <Space align="center">{body}</Space>
      </ToggleWrapper>
    );
  }, [body, margin, rest]);

  if (tooltip) {
    return (
      <Popover content={tooltip} placement={tooltipPlacement}>
        {renderBody()}
      </Popover>
    );
  } else {
    return renderBody();
  }
};
