import React from 'react';
import { COLORS } from '@ydistri/ds';
import { styled } from 'styled-components';

const ArrowImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.REBRAND_DARK};
  border-radius: 100%;
  height: 56px;
  width: 56px;
`;

const ArrowImage = styled.img`
  border-radius: 100%;
  width: 20px;
  height: 14px;
  transition: 0.5s all;
`;

const BaseButton = styled.button`
  background-color: ${COLORS.REBRAND_ORANGE};
  color: ${COLORS.REBRAND_DARK};

  height: 72px;
  border-radius: 36px;
  padding-left: 36px;

  font-family: 'Aeonik', Roboto, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: bold;
  border: 1px solid ${COLORS.REBRAND_ORANGE};

  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s all;

  align-self: flex-start;
  cursor: pointer;

  svg {
    transition: 0.5s all;
    stroke: ${COLORS.REBRAND_ORANGE};
    fill: ${COLORS.REBRAND_ORANGE};
  }

  @media (max-width: 1024px) {
    align-self: center;
  }

  &:hover {
    background-color: ${COLORS.REBRAND_DARK};
    color: ${COLORS.REBRAND_ORANGE};

    ${ArrowImageWrapper} {
      transition: 0.5s all;
      background-color: ${COLORS.REBRAND_ORANGE};
      stroke: ${COLORS.REBRAND_DARK};
      fill: ${COLORS.REBRAND_DARK};

      svg {
        stroke: ${COLORS.REBRAND_DARK};
        fill: ${COLORS.REBRAND_DARK};
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus ${ArrowImage} {
    transform: translate(6px, -6px);
  }
`;

interface NLIArrowButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  button?: string;
}

export const NLIArrowButton: React.FC<NLIArrowButtonProps> = ({ button, ...rest }) => {
  return (
    <BaseButton {...rest}>
      {button}
      <ArrowImageWrapper>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.30182 15.3018L0.594708 14.5947C0.399446 14.3994 0.399446 14.0829 0.594708 13.8876L12.0286 2.45369L5.74245 2.46076C5.60913 2.4613 5.48111 2.40858 5.38684 2.31431C5.29257 2.22004 5.23985 2.09203 5.2404 1.95871L5.24747 0.96169C5.24692 0.828372 5.29964 0.700357 5.39391 0.606087C5.48818 0.511816 5.6162 0.459097 5.74952 0.459644L14.4257 0.452573C14.6246 0.452449 14.8153 0.531295 14.956 0.671776L15.2247 0.940477C15.3634 1.08241 15.4419 1.27239 15.444 1.47081L15.4369 10.147C15.4374 10.2803 15.3847 10.4083 15.2904 10.5026C15.1962 10.5969 15.0682 10.6496 14.9348 10.6491H13.9307C13.7991 10.6504 13.6724 10.5987 13.5793 10.5056C13.4861 10.4124 13.4344 10.2858 13.4358 10.1541L13.4428 3.8679L2.00892 15.3018C1.81366 15.4971 1.49708 15.4971 1.30182 15.3018Z" />
        </svg>
      </ArrowImageWrapper>
    </BaseButton>
  );
};
