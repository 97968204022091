import React, { useCallback, useMemo } from 'react';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { ApiFilterType } from '@ydistri/ds';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

interface ComparisonSelectionProps {
  itemType: ApiFilterType;
  value: string;
  onChange?: (newComparisonType: string) => void;
}

const textItems: MenuProps['items'] = [
  {
    key: 'contains',
    label: 'Contains',
  },
  {
    key: 'notcontains',
    label: 'Not contains',
  },
];

const numericItems: MenuProps['items'] = [
  {
    key: 'eq',
    label: 'Equals',
  },
  {
    key: 'ne',
    label: 'Not equals',
  },
  {
    key: 'gt',
    label: 'Greater than',
  },
  {
    key: 'ge',
    label: 'Greater than or equal',
  },
  {
    key: 'lt',
    label: 'Less than',
  },
  {
    key: 'le',
    label: 'Less than or equal',
  },
];

const booleanItems: MenuProps['items'] = [
  {
    key: 'true',
    label: 'Yes',
  },
  {
    key: 'false',
    label: 'No',
  },
];

const comparisonSelectItems: Record<ApiFilterType, MenuProps['items']> = {
  text: textItems,
  number: numericItems,
  boolean: booleanItems,
  options: [],
};

/**
 * Allows to select the comparison type for a filter. The options are based on
 * the type. There is a different set of options for text and numeric types.
 * @param itemType
 * @param value
 * @param onChange
 * @constructor
 */
const ComparisonSelection: React.FC<ComparisonSelectionProps> = ({ itemType, value, onChange }) => {
  // const [currentValue, setCurrentValue] = useState<string>(itemType === 'text' ? 'contains' : 'ge');

  const buttonLabel = useMemo(() => {
    const usedItems = comparisonSelectItems[itemType];

    const foundItem = usedItems?.find(item => item?.key === value);
    if (foundItem) {
      return get(foundItem, 'label');
    }
  }, [value, itemType]);

  const handleMenuClick: MenuClickEventHandler = useCallback(
    ({ key }) => {
      // setCurrentValue(key);
      if (onChange) {
        onChange(key);
      }
    },
    [onChange],
  );

  const menuProps = useMemo(
    () => ({
      items: comparisonSelectItems[itemType],
      onClick: handleMenuClick,
    }),
    [handleMenuClick, itemType],
  );

  return (
    <Dropdown menu={menuProps}>
      <Button>
        <Space>
          {buttonLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default ComparisonSelection;
