import debug from 'debug';

/**
 * Creates a debug logger. Name is prefixed with `ydistri:` to make it easier to filter.
 * When multiple names are provided, they are joined to provide a hierarchical structure.
 * @param names one or more names to use for the logger.
 */
export const createDebugLog = (...names: string[]): debug.Debugger => {
  return debug(`ydistri:${names.join(':')}`);
};
