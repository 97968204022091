import React from 'react';
import logo from '../../assets/logo.svg';
import { Text, Title } from '@ydistri/ds';
import { ASPBackground, ASPLogo, ASPShadowBox } from './applicationStatusPageComponents';

export const ApplicationStatusOfflinePage: React.FC = () => {
  return (
    <ASPBackground>
      <ASPShadowBox>
        <ASPLogo src={logo} />
        <Title $size="large">Application is offline.</Title>
        <Text>Sorry for the inconvenience, we are investigating the matter.</Text>
      </ASPShadowBox>
    </ASPBackground>
  );
};
