export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function lowercaseFirstLetter(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Takes a string containing words separated by spaces and returns a string with the first letter of each word capitalized.
 * @param what String to transformed
 * @returns string transformed to PascalCase
 */
export const transformToPascalCase = (what: string): string => {
  return what
    .replace(/(\w)(\w*)/g, function (_, firstChar: string, restOfWord: string) {
      return `${firstChar.toUpperCase()}${restOfWord.toLowerCase()}`;
    })
    .replace(/\s+/g, '');
};

export const padLeft = (stringToPad: string, value: string, size: number): string => {
  return stringToPad.padStart(size, value);
};

/**
 * Takes a string in camelCase and transforms it to normal sentence.
 *
 * example:
 *  camelCaseToNormal('camelCaseToNormal') => 'Camel case to normal'
 *
 * @param str String to be transformed
 * @returns string transformed to normal sentence
 */
export const camelCaseToNormal = (str: string): string => {
  return str
    .replace(/([A-Z])/g, ' $1') // Insert a space before each capital letter
    .replace(/^./, firstChar => firstChar.toUpperCase()) // Capitalize the first character
    .replace(/ (\w)/g, (match, letter: string) => ' ' + letter.toLowerCase()) // Lowercase the first character of each subsequent word
    .trim(); // Remove any leading or trailing spaces
};

/**
 * Inserts a string into another string at the specified position.
 * @param originalString
 * @param insertedText
 * @param start
 * @param end
 */
export const insertText = (
  originalString: string,
  insertedText: string,
  start: number,
  end: number,
): string => {
  return originalString.substring(0, start) + insertedText + originalString.substring(end);
};

const digitsOnlyRegex = new RegExp('^-?\\d+$');
export const isDigitsOnly = (content: string): boolean => {
  return digitsOnlyRegex.test(content);
};

/**
 * Returns the plural or singular form of a word depending on the count.
 * @type {function}
 * @returns {string}
 * @param count The number of items. Anything bigger than 1 will return the plural form.
 * @param singular The singular form of the word
 * @param plural Optional. If not provided, the plural form will be the singular form with an 's' appended.
 */
export const plural = (count: number, singular: string = '', plural?: string): string => {
  if (count === 1) {
    return singular;
  } else {
    return plural ?? `${singular}s`;
  }
};
