import React, { useMemo } from 'react';
import { ArrayTooltipContent, ArrayTooltipContentData } from './ArrayTooltipContent';

interface SimpleTooltipContentProps {
  title: string;
  value: number | undefined;
  postfix?: string;
}

export const SimpleTooltipContent: React.FC<SimpleTooltipContentProps> = ({
  title,
  value,
  postfix,
}) => {
  const data: ArrayTooltipContentData[] = useMemo(
    () => [
      {
        title,
        value: value ?? 0,
        postfix,
        isTitle: true,
      },
    ],
    [postfix, title, value],
  );

  return useMemo(() => <ArrayTooltipContent data={data} />, [data]);
};
