/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import { ApplicationConfigurationResponseDataResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';
export class ApplicationConfigurations<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags ApplicationConfigurations
   * @name ApplicationConfigurationsList
   * @request GET:/ApplicationConfigurations
   * @response `200` `ApplicationConfigurationResponseDataResponse` OK
   */
  applicationConfigurationsList = (params: RequestParams = {}) =>
    this.http.request<ApplicationConfigurationResponseDataResponse, any>({
      path: `/ApplicationConfigurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
