import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
  BarController,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

export function registerChartJs(): void {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    annotationPlugin,
    Filler,
  );
}
