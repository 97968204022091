/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import { Condition, RegionResponseCollectionResponse, Sorting } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';
export class Regions<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Regions
   * @name RegionsList
   * @request GET:/Regions
   * @response `200` `RegionResponseCollectionResponse` OK
   */
  regionsList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RegionResponseCollectionResponse, any>({
      path: `/Regions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
