import { ReactNode } from 'react';
import { styled } from 'styled-components';

export interface LeftMenuItem {
  parentId?: string;
  subpageId?: string;
  key: string;
  path: string;
  label: string;
  shortcut: string;
  isOpened: boolean;
  isSelected: boolean;
  isChild: boolean;
  hasChild: boolean;
  icon: ReactNode;
}

export const Version = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  color: grey;
`;
