import type { SerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import { Condition, Operation, SortDirection, Sorting } from '@ydistri/api-sdk';
import { SorterResult } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import React from 'react';
import { createDebugLog } from '../../../../../apps/ydistri/src/app/lib/utils/logging';
import isEqual from 'lodash/isEqual';
import { ApiParams } from '../common/commonTypes';

const debugLog = createDebugLog('InfiniteScrollTable', 'lib');

export const INFINITE_PAGE_SIZE = 50;

export type ApiFilterType = 'text' | 'number' | 'boolean' | 'options';

enum ColumnSortering {
  NONE,
  LOCAL,
  API,
}

enum ColumnFiltering {
  NONE,
  LOCAL,
  API,
}

/**
 * Extension of the Ant Design's Table to include name of the attribute in the
 * API response that should be used for sorting.
 */
export interface YColumnsType<T> extends ColumnType<T> {
  apiColumnName?: string;
  apiFilterable?: boolean;
  apiFilterType?: ApiFilterType;
  hideable?: boolean;
  /* Number format used for Excel when exporting see https://support.microsoft.com/en-gb/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68*/
  exportNumberFormat?: string;
  columnSortering?: ColumnSortering;
  columnFiltering?: ColumnFiltering;
}

export interface InfiniteScrollParams extends ApiParams {
  skip?: number;
  top?: number;
  search?: string;
  sortings?: Sorting[];
  conditions?: Condition[];
}

export function serializeQueryArgsForInfiniteScroll<T>(): SerializeQueryArgs<T> {
  return ({ endpointName }) => {
    return endpointName;
  };
}

export function serializeApiBasedQueryArgs<T extends ApiParams>(): SerializeQueryArgs<T> {
  return ({ endpointName, queryArgs }) => {
    if (queryArgs.projectCode) {
      return `${endpointName}("${queryArgs.projectCode}")`;
    } else {
      return endpointName;
    }
  };
}

export function serializeFullApiBasedQueryArgs<
  T extends InfiniteScrollParams,
>(): SerializeQueryArgs<T> {
  return ({ endpointName, queryArgs }) => {
    let result = endpointName;

    if (queryArgs.projectCode) {
      result += `("${queryArgs.projectCode}")`;
    }

    const conditions = queryArgs.conditions;
    let txtConditions = '';
    if (conditions && conditions.length > 0) {
      txtConditions = conditions.map(c => `${c.fieldName} ${c.operation} ${c.value}`).join('; ');
    }

    let txtSortings = '';
    if (queryArgs.sortings && queryArgs.sortings.length > 0) {
      txtSortings = queryArgs.sortings.map(s => `${s.fieldName} ${s.direction}`).join('; ');
    }
    result += `(conditions=${txtConditions}, search=${
      queryArgs.search ?? ''
    }, sortings=${txtSortings})`;

    return result;
  };
}

export interface OtherArgs<T extends InfiniteScrollParams> {
  arg: T;
}

export interface CompoundSelectedKey {
  comparisonType: string;
  value: React.Key[];
}

export function mergeForInfiniteScroll<T extends object, U extends InfiniteScrollParams>(
  idCheckKey?: keyof T,
) {
  return (currentCache: T[], newItems: T[], otherArgs: OtherArgs<U>): void => {
    if (otherArgs.arg.skip === 0) {
      currentCache.splice(0, currentCache.length);
    }

    if (newItems.length === 0) return;
    if (idCheckKey === undefined) {
      currentCache.push(...newItems);
      return;
    }

    const hasRequiredId = Object.hasOwn(newItems[0], idCheckKey);
    if (!hasRequiredId) return;

    const availableIdMap = new Set(currentCache.map(c => c[idCheckKey] as string | number));

    newItems.forEach(ni => {
      const id = ni[idCheckKey] as string | number;
      if (!availableIdMap.has(id)) {
        currentCache.push(ni);
      }
    });
  };
}

export function forceRefetchForInfiniteScroll<T extends InfiniteScrollParams | undefined>() {
  return ({ currentArg, previousArg }: { currentArg: T; previousArg: T }): boolean => {
    return !isEqual(currentArg, previousArg);
  };
}

/**
 * Convert sorter result from Ant Design table to sorting parameters for backend.
 * Called automatically by the InfiniteScrollTable component in the onChange handler.
 * @param data parameters from Ant Design table passed to the table's onChange event handler
 */
export const sorterResultToSorting = <T>(data: SorterResult<T>[]): Sorting[] => {
  return data
    .map(sorterResult => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const column = sorterResult.column as YColumnsType<T>;

      const fieldName = column.apiColumnName ?? sorterResult.field?.toString();

      const result: Sorting = {
        fieldName: fieldName ?? '',
        direction: sorterResult.order === 'ascend' ? SortDirection.Asc : SortDirection.Desc,
      };
      return result;
    })
    .filter(sorting => sorting.fieldName.length > 0);
};

/**
 * Extract default sorting settings from column definitions and return
 * an array of sorting parameters to be passed to backend.
 * To be used in the initial query to the backend.
 * Example:
 * <code>
 *   const { productIdColumnWidth } = useColumnWidth();
 *
 *    const columns: YColumnsType<SkuRedistributionResponse> = useMemo(() => {
 *       return [
 *         {
 *         title: 'Product ID',
 *         key: 'customerProductId',
 *         width: productIdColumnWidth,
 *         dataIndex: ['product', 'customerId'],
 *         sorter: true,
 *         apiColumnName: 'Product/CustomerId',
 *       },
 *           {
 *         title: 'Value',
 *         key: 'value',
 *         dataIndex: 'value',
 *         align: 'right',
 *         render: (value: number) => formatNumber(value, 2),
 *         sorter: { multiple: 1 },
 *         sortDirections: ['descend', 'ascend'],
 *         defaultSortOrder: 'descend',
 *         apiColumnName: 'Value',
 *       },
 *       ]
 *
 *    }, [productIdColumnWidth]);
 *
 *     const [params, setParams] = useState<InfiniteScrollParams>({
 *     skip: 0,
 *     top: 50,
 *     sortings: getDefaultSorting(columns),
 *   });
 *
 *    const { data: redistributionData, isFetching } = useGetCalculationCategoryPairingsQuery({
 *     calculationId,
 *     categoryId,
 *     ...params,
 *   });
 * </code>
 * @param columns Definition of columns for Ant Design table
 */
export const getDefaultSorting = <T>(columns: YColumnsType<T>[]): Sorting[] => {
  return columns
    .filter(column => Object.hasOwn(column, 'defaultSortOrder'))
    .sort((left, right) => {
      const leftOrder = typeof left.sorter === 'object' ? (left.sorter.multiple ?? 0) : 0;
      const rightOrder = typeof right.sorter === 'object' ? (right.sorter.multiple ?? 0) : 0;
      return leftOrder - rightOrder;
    })
    .map(column => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const col = column as ColumnType<T>;
      return {
        fieldName: column.apiColumnName ?? '',
        direction: col.defaultSortOrder === 'ascend' ? SortDirection.Asc : SortDirection.Desc,
      };
    })
    .filter(sorting => sorting.fieldName.length > 0);
};

/**
 * Create parameters for the sortings part of the backend API call.
 * @param sorter
 */
const createSortings = <T>(sorter: SorterResult<T> | SorterResult<T>[]): Sorting[] => {
  let sortings: Sorting[] = [];

  if (Array.isArray(sorter)) {
    sortings = sorterResultToSorting(sorter);
  } else {
    if (sorter.order) {
      sortings = sorterResultToSorting([sorter]);
    }
  }

  return sortings;
};

const getApiColumnName = <T>(columns: YColumnsType<T>[], key: string): string => {
  const column = columns.find(c => c.key === key);
  if (column) {
    return column.apiColumnName ?? key;
  }
  return key;
};

const getApiColumnType = <T>(columns: YColumnsType<T>[], key: string): ApiFilterType => {
  const column = columns.find(c => c.key === key);
  if (column) {
    return column.apiFilterType ?? 'text';
  }
  return 'text';
};

const translateCompareOperation = (comparisonType?: string) => {
  switch (comparisonType) {
    case 'eq':
      return Operation.Eq;
    case 'ne':
      return Operation.Ne;
    case 'gt':
      return Operation.Gt;
    case 'ge':
      return Operation.Ge;
    case 'lt':
      return Operation.Lt;
    case 'le':
      return Operation.Le;
    case 'contains':
      return Operation.Contains;
    case 'notcontains':
      return Operation.Notcontains;
    case 'startswith':
      return Operation.Startswith;
    case 'endswith':
      return Operation.Endswith;
    case 'true':
      return Operation.Eq;
    case 'false':
      return Operation.Ne;
    default:
      return Operation.Contains;
  }
};

const createFilters = <T>(
  filters: Record<string, FilterValue | null>,
  columns: YColumnsType<T>[],
): Condition[] | undefined => {
  const result: Condition[] = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== null) {
      const columnType = getApiColumnType(columns, key);
      switch (columnType) {
        case 'text':
        case 'boolean':
        case 'number': {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const compoundKey = JSON.parse(value.toString()) as CompoundSelectedKey;
          result.push({
            fieldName: getApiColumnName(columns, key),
            operation: translateCompareOperation(compoundKey.comparisonType),
            value: compoundKey.value,
          });
          break;
        }
        case 'options':
          result.push({
            fieldName: getApiColumnName(columns, key),
            operation: Operation.In,
            value: `('${value.join(', ')}')`, //generated classes for backend API are not able to handle array of strings this way
          });
          break;
      }
    }
  });

  return result.length > 0 ? result : undefined;
};

export const createParamsForInfiniteScroll = <T extends object, U extends InfiniteScrollParams>(
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<T> | SorterResult<T>[],
  currentParams: U,
  columns: YColumnsType<T>[] | undefined,
): U => {
  const result = { ...currentParams };
  debugLog('createParamsForInfiniteScroll, current parameters: %s', JSON.stringify(currentParams));

  debugLog('sorters %s', JSON.stringify(sorter));
  const sortings = createSortings(sorter);
  if (sortings.length > 0) {
    result.sortings = sortings;
    result.skip = 0;
  } else {
    result.sortings = undefined;
  }

  if (columns && columns.length > 0) {
    //conditions might have been set from the outside as well
    //we need to preserve those conditions not related to the table filters
    //which are specified by the column definitions.

    const newTableFilters = createFilters(filters, columns);

    //get all field names from columns that are filterable
    const filterableColumnsApiNames = columns
      .filter(column => column.apiFilterable)
      .map(column => column.apiColumnName);

    //extract all conditions that are not related to the table filters, meaning their field name is not in the list of filterable columns
    const nonTableFilters =
      currentParams.conditions?.filter(
        item => !filterableColumnsApiNames.includes(item.fieldName),
      ) ?? [];

    //combine the non-table filters with the new table filters
    const newConditions = [...nonTableFilters, ...(newTableFilters ?? [])];

    if (newConditions.length > 0) {
      result.conditions = newConditions;
    } else {
      delete result.conditions;
    }

    result.skip = 0;
  } else {
    result.conditions = undefined;
  }

  debugLog('createParamsForInfiniteScroll, new parameters: %s', JSON.stringify(result));
  return result;
};
