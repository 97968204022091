import { YColumnsType } from '@ydistri/ds';
import { useCallback, useMemo } from 'react';
import { makeColumnFilterable } from '../../../../../apps/ydistri/src/app/lib/tables/tablesLocalFilterLib';

/**
 * Modify Ant Design Table columns definition so that columns with apiFilterable property set to true
 * will contain all necessary properties to display a custom FilterComponent when user clicks on the
 * and search the data based on the column definition.
 * @param columns
 */
export function injectLocalFilters<T>(columns: YColumnsType<T>[]): YColumnsType<T>[] {
  return columns.map(column => {
    if ('apiFilterable' in column) {
      if (column.apiFilterable) {
        return makeColumnFilterable(column);
      }
    }
    return column;
  });
}

/**
 * Modify Ant Design Table columns definition so that columns with apiFilterable property set to true
 * will display a custom FilterComponent when user clicks on the filter icon in the column header.
 * The hook will also add the onFilter property to the column definition so that the table will
 * filter the data locally provided that the Ant Design table is given the whole
 * data in the dataSource property.
 * @param columns
 */
export function useInjectLocalFilters<T>(columns: YColumnsType<T>[]): YColumnsType<T>[] {
  return useMemo(() => injectLocalFilters(columns), [columns]);
}

export type InjectLocalFilterProvider = <T>(columns: YColumnsType<T>[]) => YColumnsType<T>[];

export const useInjectLocalFiltersProvider = (): InjectLocalFilterProvider => {
  return useCallback(<T,>(columns: YColumnsType<T>[]): YColumnsType<T>[] => {
    return injectLocalFilters(columns);
  }, []);
};
