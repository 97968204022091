import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/es/config-provider';
import StyledComponentsTheme from '../../../../../apps/ydistri/src/app/styles/StyledComponentsTheme';
import { computeRemSize, STYLE, COLORS } from '@ydistri/ds';
import {
  baseButtonStyles,
  buttonVariantColorStyles,
  buttonVariantSizeStyles,
  buttonVariantTextStyles,
} from '../Button/Button';
import aeonik from '../../assets/fonts/aeonik-bold.woff';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(advancedFormat);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);

// eslint-disable-next-line @typescript-eslint/naming-convention
const GlobalStyle = createGlobalStyle`
  :root {
    font-size: ${props => props.theme.rootFontSize}px;
    font-family: Roboto, Helvetica, Arial, sans-serif; //'Segoe UI',
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${aeonik}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .rc-virtual-list-holder {
    ${STYLE.SCROLLBAR}
  }

  .ant-list {
    font-size: 1rem;
  }

  .ant-table-header table {
    table-layout: auto !important;
  }

  .ant-select-arrow {
    font-size: ${computeRemSize(10)} !important;
    height: ${computeRemSize(10)} !important;
    inset-inline-end: ${computeRemSize(11)} !important;
    margin-top: calc(-1 * ${computeRemSize(5)}) !important;
  }

  .ant-table {
    thead.ant-table-thead {
      font-size: 1rem;
    }

    thead.ant-table-thead th,
    tr:not(.ant-table-measure-row) td {
      svg {
        font-size: ${computeRemSize(13)};
      }
    }
  }

  .ant-modal .ant-modal-footer {
    display: flex;
    justify-content: flex-end;

    .ant-btn-default {
      ${baseButtonStyles}
      ${({ theme }) => buttonVariantColorStyles(theme, 'ghost')}
      ${({ theme }) => buttonVariantTextStyles(theme, 'regular')}
      ${({ theme }) => buttonVariantSizeStyles(theme, 'normal')}
    }

    .ant-btn-primary {
      ${baseButtonStyles}
      ${({ theme }) => buttonVariantColorStyles(theme, 'primary')}
      ${({ theme }) => buttonVariantTextStyles(theme, 'regular')}
      ${({ theme }) => buttonVariantSizeStyles(theme, 'normal')}
    }
  }

  .ant-dropdown-menu-item {
    font-size: ${computeRemSize(16)} !important;
    padding: ${computeRemSize(3)} ${computeRemSize(16)} !important;
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }


  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  tr.bg-orange-2 {
    background-color: ${COLORS.PRIMARY_STEPS[0]};
  }

  tr.bg-orange-3 {
    background-color: ${COLORS.PRIMARY_STEPS[1]};
  }

  tr.bg-orange-4 {
    background-color: ${COLORS.PRIMARY_STEPS[2]};
  }

  tr.bg-orange-5 {
    background-color: ${COLORS.PRIMARY_STEPS[3]};
  }

`;

interface GlobalProps {
  children?: ReactNode;
}

export const computeFontSize = (): number =>
  (STYLE.BASE_FONT_SIZE * Math.max(Math.min(window.screen.width, 1920), 1280)) / 1920;

export const Global: React.FC<GlobalProps> = ({ children }) => {
  const [computedFontSize, setComputedFontSize] = useState(computeFontSize());

  const handleResize = useCallback(() => {
    setComputedFontSize(computeFontSize());
  }, []);

  const pixelSize = useMemo(() => computedFontSize / STYLE.BASE_FONT_SIZE, [computedFontSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const antdTheme: ConfigProviderProps['theme'] = useMemo(
    () => ({
      token: {
        colorPrimary: STYLE.PRIMARY_COLOR,
        colorLinkHover: STYLE.PRIMARY_COLOR,
        controlHeight: pixelSize * 24,
        // controlHeight: baseFontSize,
        fontSize: computedFontSize,
        fontFamily: `Roboto, Helvetica, Arial, sans-serif`, //'Segoe UI'
        borderRadius: STYLE.BORDER_RADIUS,
        fontSizeHeading1: pixelSize * 38,
        fontSizeHeading2: pixelSize * 30,
        fontSizeHeading3: pixelSize * 24,
        fontSizeHeading4: pixelSize * 20,
        fontSizeHeading5: pixelSize * 16,
        fontSizeLG: pixelSize * 16,
        fontSizeSM: pixelSize * 12,
        fontSizeXL: pixelSize * 20,
        size: pixelSize * 16,
        sizeLG: pixelSize * 24,
        sizeMD: pixelSize * 20,
        sizeMS: pixelSize * 16,
        sizeSM: pixelSize * 12,
        sizeXL: pixelSize * 32,
        sizeXS: pixelSize * 8,
        sizeXXL: pixelSize * 48,
        sizeXXS: pixelSize * 4,
        controlInteractiveSize: pixelSize * 16,
        controlOutlineWidth: pixelSize * 2,
        controlPaddingHorizontal: pixelSize * 12,
        controlPaddingHorizontalSM: pixelSize * 8,
        fontSizeIcon: pixelSize * 12,
        margin: pixelSize * 16,
        marginLG: pixelSize * 24,
        marginMD: pixelSize * 20,
        marginSM: pixelSize * 12,
        marginXL: pixelSize * 32,
        marginXS: pixelSize * 8,
        marginXXL: pixelSize * 48,
        marginXXS: pixelSize * 4,
        opacityLoading: pixelSize * 0.65,
        padding: pixelSize * 16,
        paddingContentHorizontal: pixelSize * 16,
        paddingContentHorizontalLG: pixelSize * 24,
        paddingContentHorizontalSM: pixelSize * 16,
        paddingContentVertical: pixelSize * 12,
        paddingContentVerticalLG: pixelSize * 16,
        paddingContentVerticalSM: pixelSize * 8,
        paddingLG: pixelSize * 24,
        paddingMD: pixelSize * 20,
        paddingSM: pixelSize * 12,
        paddingXL: pixelSize * 32,
        paddingXS: pixelSize * 8,
        paddingXXS: pixelSize * 4,
      },
    }),
    [computedFontSize, pixelSize],
  );

  return (
    <ConfigProvider theme={antdTheme}>
      <StyledComponentsTheme computedFontSize={computedFontSize}>
        <GlobalStyle />
        {children}
      </StyledComponentsTheme>
    </ConfigProvider>
  );
};
