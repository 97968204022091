import { COLORS, STYLE } from '@ydistri/ds';
import React, { PropsWithChildren } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { theme } from 'antd';

const yTheme: DefaultTheme = {
  rootFontSize: `${STYLE.BASE_FONT_SIZE}px`,
  colors: {
    primary: COLORS.PRIMARY,
    dark: COLORS.COLOR_DARK,
    light: 'white',
    border: COLORS.BORDER,
    hover: COLORS.PRIMARY_LIGHT,
    pageBackground: COLORS.PAGE_BACKGROUND,
    danger: COLORS.DANGER,
  },
  item: {
    transition: '0.3s all',
    hovered: {
      backgroundColor: COLORS.GREY_LIGHT,
      backgroundColorDark: COLORS.MENU_DARK_BACKGROUND_HOVER,
    },
    selected: {
      backgroundColor: COLORS.PRIMARY_STEPS[0],
      backgroundColorDark: COLORS.MENU_DARK_BACKGROUND_SELECTED,
    },
  },
  globalPadding: STYLE.GLOBAL_PADDING,
  iconColorSchemes: {
    none: {
      normal: {
        backgroundColor: 'transparent',
        fill: 'currentColor',
        color: 'currentColor',
        borderColor: 'rgba(29, 27, 37, 0.15)',
      },
      highlighted: {
        fill: '#f29718',
        backgroundColor: 'transparent',
        borderColor: '#f5940d',
        color: '#f29718',
      },
    },
    default: {
      normal: {
        fill: '#ffb552',
        backgroundColor: '#fff9ef',
        color: '#ffb552',
        borderColor: '#ffb552',
      },
      highlighted: {
        fill: '#f5940d',
        backgroundColor: '#fff6e4',
        borderColor: '#f5940d',
        color: '#f5940d',
      },
    },
    defaultInverse: {
      normal: {
        fill: '#ffb552',
        backgroundColor: '#ffb552',
        color: 'white',
        borderColor: 'white',
      },
      highlighted: {
        fill: '#fff6e4',
        backgroundColor: '#f5940d',
        borderColor: '#fff6e4',
        color: '#fff6e4',
      },
    },
    danger: {
      normal: {
        fill: '#c77e7d',
        borderColor: '#c77e7d',
        backgroundColor: '#fcf2f5',
        color: '#c77e7d',
      },
      highlighted: {
        fill: '#e7504e',
        borderColor: '#e7504e',
        backgroundColor: '#fcf2f5',
        color: '#e7504e',
      },
    },
    neutral: {
      normal: {
        borderColor: '#c2d3f3',
        fill: '#c2d3f3',
        backgroundColor: '#fbfcff',
        color: '#c2d3f3',
      },
      highlighted: {
        borderColor: '#3f61a2',
        fill: '#3f61a2',
        backgroundColor: '#f3f8ff',
        color: '#3f61a2',
      },
    },
    disabled: {
      normal: {
        borderColor: '#c2d3f3',
        fill: '#c2d3f3',
        backgroundColor: '#fbfcff',
        color: '#c2d3f3',
      },
      highlighted: {
        borderColor: '#c2d3f3',
        fill: '#c2d3f3',
        backgroundColor: '#fbfcff',
        color: '#c2d3f3',
      },
    },
  },
};

const getTheme = (computedFontSize: number) => ({
  ...yTheme,
  rootFontSize: computedFontSize,
});

interface StyledComponentsThemeProps extends PropsWithChildren {
  computedFontSize: number;
}

const { useToken } = theme;

const StyledComponentsTheme: React.FC<StyledComponentsThemeProps> = ({
  computedFontSize,
  children,
}) => {
  const { token } = useToken();

  const theme = getTheme(computedFontSize);
  theme.colors.textDisabled = token.colorTextDisabled;
  theme.colors.backgroundDisabled = token.colorBgContainerDisabled;
  theme.colors.colorFillAlter = token.colorFillAlter;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledComponentsTheme;
