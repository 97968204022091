import { styled } from 'styled-components';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { Switch } from 'antd';
import { ConfigurationFieldStatus, getColorBasedOnStatusAndOverwrite } from '@ydistri/utils';
import {
  getIconBasedOnStatusAndOverwrite,
  OverwrittenIcon,
} from '../getIconBasedOnStatusAndOverwrite';
import { computeRemSize } from '../styles/style';

const SwitchWrapper = styled.div<{ $borderColor: CSSProperties['color'] }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${p => p.$borderColor};
`;

const StyledSwitch = styled(Switch)<{
  $borderColor: CSSProperties['color'];
  $switchColor?: CSSProperties['color'];
}>`
  font-size: 1rem;
  min-width: ${computeRemSize(42)};
  height: ${computeRemSize(21)};
  line-height: ${computeRemSize(21)};

  border: ${computeRemSize(2)} solid ${p => p.$borderColor ?? 'transparent'};

  &.ant-switch-checked {
    background-color: ${p => p.$switchColor ?? '#65c26d'} !important;

    .ant-switch-handle {
      inset-inline-start: calc(100% - ${computeRemSize(18)});
    }

    &:hover {
      background-color: ${p => p.$switchColor ?? '#65c26d'};
    }
  }

  &:disabled {
    //opacity: 0.35;
  }

  .ant-switch-handle {
    top: 0;
    inset-inline-start: 0;
    width: ${computeRemSize(17)};
    height: ${computeRemSize(17)};
  }
`;

export interface ConfigurationSwitchProps {
  disabled?: boolean;
  overwritten?: boolean;
  overwrittenIcon?: OverwrittenIcon;
  parentException?: boolean;
  status: ConfigurationFieldStatus;
  value: boolean;
  onChange?: (value: string) => void;
  includeEmptySpace?: boolean;
  switchColor?: CSSProperties['color'];
}

const EmptySpace = styled.span`
  width: 1em;
  height: 1em;
`;

export const ConfigurationSwitch: React.FC<ConfigurationSwitchProps> = ({
  disabled = false,
  overwritten = false,
  overwrittenIcon = OverwrittenIcon.SLIDERS,
  parentException = false,
  status = ConfigurationFieldStatus.DEFAULT,
  value,
  onChange = () => {},
  includeEmptySpace = false,
  switchColor,
}) => {
  const borderColor: CSSProperties['color'] = useMemo(
    () => getColorBasedOnStatusAndOverwrite(status, overwritten, parentException),
    [overwritten, parentException, status],
  );

  const suffix = useMemo(
    () =>
      getIconBasedOnStatusAndOverwrite(status, overwritten, overwrittenIcon) ?? (
        <EmptySpace>&nbsp;</EmptySpace>
      ),
    [status, overwritten, overwrittenIcon],
  );

  const onChangeHandler = useCallback((value: boolean) => onChange(value ? '1' : '0'), [onChange]);

  return (
    <SwitchWrapper $borderColor={borderColor}>
      {includeEmptySpace && <EmptySpace>&nbsp;</EmptySpace>}
      <StyledSwitch
        $borderColor={borderColor}
        $switchColor={switchColor}
        checked={value}
        disabled={status === ConfigurationFieldStatus.LOADING || disabled}
        onChange={onChangeHandler}
        // size="small"
      />
      {suffix}
    </SwitchWrapper>
  );
};
