import React from 'react';
import { ConfigurationFieldStatus } from '@ydistri/utils';
import { Spinner } from '@ydistri/ds';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsSliders } from 'react-icons/bs';
import { MdWarning } from 'react-icons/md';

export enum OverwrittenIcon {
  SLIDERS = 'sliders',
  WARNING = 'warning',
}

const overwrittenIcons: Record<OverwrittenIcon, React.ReactNode> = {
  [OverwrittenIcon.SLIDERS]: <BsSliders />,
  [OverwrittenIcon.WARNING]: <MdWarning />,
};

export const getIconBasedOnStatusAndOverwrite = (
  status: ConfigurationFieldStatus,
  overwritten: boolean,
  overwrittenIcon: OverwrittenIcon = OverwrittenIcon.SLIDERS,
): React.ReactNode => {
  switch (status) {
    case ConfigurationFieldStatus.SUCCESS:
      return overwritten ? overwrittenIcons[overwrittenIcon] : <AiOutlineCheck />;
    case ConfigurationFieldStatus.ERROR:
      return <AiOutlineClose />;
    case ConfigurationFieldStatus.LOADING:
      return <Spinner />;
    case ConfigurationFieldStatus.DEFAULT:
      return overwritten ? overwrittenIcons[overwrittenIcon] : undefined;
  }
};
