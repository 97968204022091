import { styled, css } from 'styled-components';
import { COLORS, computeRemSize } from '@ydistri/ds';
import BarLinePartition, { BarLinePartitionProps } from './BarLinePartition';
import React, { CSSProperties } from 'react';

export enum BarLineSizes {
  XLARGE = 'xlarge',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum BarLineTypes {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export const barSizes: Record<BarLineSizes, number> = {
  [BarLineSizes.XLARGE]: 150,
  [BarLineSizes.LARGE]: 80,
  [BarLineSizes.MEDIUM]: 40,
  [BarLineSizes.SMALL]: 12,
};

interface StyledBarLineProps {
  $size: BarLineSizes;
  $type: BarLineTypes;
  $backgroundColor: CSSProperties['backgroundColor'];
}

const StyledBarLine = styled.div<StyledBarLineProps>`
  display: flex;

  ${p =>
    p.$type === BarLineTypes.HORIZONTAL &&
    css`
      height: ${computeRemSize(barSizes[p.$size])};
      min-height: ${computeRemSize(barSizes[p.$size])};
      width: 100%;
    `}

  ${p =>
    p.$type === BarLineTypes.VERTICAL &&
    css`
      flex-direction: column;
      height: 100%;
      width: ${computeRemSize(barSizes[p.$size])};
      max-width: ${computeRemSize(barSizes[p.$size])};
    `}

  border-radius: 0.5rem;
  overflow: hidden;
  background-color: ${p => p.$backgroundColor};
`;

export interface BarLineProps {
  type?: BarLineTypes;
  size?: BarLineSizes;
  data: BarLinePartitionProps[];
  dividers?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
}

export const BarLine: React.FC<BarLineProps> = ({
  type = BarLineTypes.HORIZONTAL,
  size = BarLineSizes.MEDIUM,
  data,
  dividers = false,
  backgroundColor = COLORS.BAR_GREY,
}) => {
  return (
    <StyledBarLine $type={type} $size={size} $backgroundColor={backgroundColor}>
      {data.map((partition, index) => (
        <BarLinePartition
          //eslint-disable-next-line react/no-array-index-key
          key={index}
          type={type}
          border={dividers && index < data.length - 1}
          size={size}
          minSizeToDisplayContent={partition.minSizeToDisplayContent}
          {...partition}
        />
      ))}
    </StyledBarLine>
  );
};
