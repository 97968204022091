import { Divider, Space } from 'antd';
import React, { PropsWithChildren, useMemo } from 'react';
import { styled } from 'styled-components';
import { computeRemSize } from '../styles/style';

interface ActionsBarProps extends PropsWithChildren {
  /** Move the actions to the right side of the bar; must be placed within flexbox */
  moveToRight?: boolean;
  /**
   * Use a vertical line as divider between the actions to give them more space
   */
  useDivider?: boolean;
  /** Wrap the actions if they don't fit in one line (defaults to true) */
  wrap?: boolean;
  id?: string;
}

interface ActionsBarWrapperProps {
  $moveToRight?: boolean;
}

const ActionsBarWrapper = styled(Space)<ActionsBarWrapperProps>`
  ${p => p.$moveToRight && 'margin-left: auto;'}
  gap: ${computeRemSize(6)} !important;
`;

/**
 * Container for actions that should be placed in a horizontal bar.
 * <p>Use the ActionBar for buttons or other controls that should be placed next
 * to each other in a standardized way.</p>
 * @param moveToRight Move the actions to the right side of the bar; must be placed within flexbox
 * @param useDivider Use a vertical line as divider between the actions to give them more space
 * @param children The actions to be placed in the bar
 * @param wrap Wrap the actions if they don't fit in one line (defaults to true)
 * @param rest Additional props to be passed to the container
 * @constructor
 */
export const ActionsBar: React.FC<ActionsBarProps> = ({
  moveToRight,
  useDivider,
  children,
  wrap = true,
  ...rest
}) => {
  const split = useMemo(() => (useDivider ? <Divider type="vertical" /> : null), [useDivider]);

  const notNullChildren = React.Children.toArray(children).filter(
    child => child !== null && child !== undefined,
  );

  return (
    <ActionsBarWrapper
      direction="horizontal"
      align="center"
      wrap={wrap}
      $moveToRight={moveToRight}
      split={split}
      data-type="ActionsBar"
      {...rest}
    >
      {notNullChildren}
    </ActionsBarWrapper>
  );
};
