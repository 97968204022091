import React from 'react';
import { Column, Text } from '@ydistri/ds';
import { shortenNumber } from '@ydistri/utils';

interface BarPartitionContentProps {
  /**  Title of the segment */
  title: string;
  percentage?: number;
  value: number;
}

/**
 * Formats information to be displayed on a segment of a BarLine.
 * @param title Title of the segment
 * @param percentage Optional percentage of the segment
 * @param value Monetary value of the segment will be formatted as thousands (k), millions (M) or billions (B)
 * @constructor
 */
export const BarPartitionContent: React.FC<BarPartitionContentProps> = ({
  title,
  percentage,
  value,
}) => {
  return (
    <Column $gap="0.125rem" $padding={1}>
      <Text $size="xSmall" $bold={true}>
        {title}
      </Text>
      {percentage && (
        <Text>
          {value === 0 ? 0 : percentage}% ({shortenNumber(value)})
        </Text>
      )}
      {!percentage && <Text>{shortenNumber(value)}</Text>}
    </Column>
  );
};
