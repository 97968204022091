import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { styled } from 'styled-components';
import ComparisonSelection from './ComparisonSelection';
import { ApiFilterType, CompoundSelectedKey, computeRemSize } from '@ydistri/ds';

interface FilterComponentProps {
  columnKey: string;
  displayName: string;
  itemType: ApiFilterType;
  filter: FilterDropdownProps;
  onComparisonTypeChanged?: (key: string, comparisonType: string) => void;
}

const searchIcon = <SearchOutlined />;

const SearchPaneWrapper = styled.div`
  padding: ${computeRemSize(8)};
  display: flex;
  flex-direction: column;
  gap: ${computeRemSize(8)};
  width: 25rem;
`;

const defaultComparisonTypes: Record<ApiFilterType, string> = {
  text: 'contains',
  number: 'eq',
  boolean: 'true',
  options: '',
};

/**
 * Filter component for antd table. Displayed when user clicks on the filter icon in the column header.
 * @param filter Filter properties provided by AntDesign Table
 * @param displayName
 * @param key
 * @param itemType
 */
export const FilterComponent: React.FC<FilterComponentProps> = ({
  filter,
  displayName,
  columnKey,
  itemType,
  onComparisonTypeChanged,
}) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters, visible } = filter;
  const ref = useRef<InputRef>(null);
  const [comparisonType, setComparisonType] = useState<string>(defaultComparisonTypes[itemType]);
  const [filterValue, setFilterValue] = useState<string>(() => {
    if (selectedKeys.length === 0) {
      return '';
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const compoundKey: CompoundSelectedKey = JSON.parse(
      selectedKeys[0].toString(),
    ) as CompoundSelectedKey;
    return compoundKey.value[0].toString();
  });

  const closeFilterDropdown = useCallback(() => {
    confirm({ closeDropdown: true });
  }, [confirm]);

  const handleSearch = useCallback(() => {
    if (filterValue.length === 0) {
      if (clearFilters) {
        clearFilters();
      }
      closeFilterDropdown();
    } else {
      confirm();
    }
  }, [clearFilters, closeFilterDropdown, confirm, filterValue.length]);

  const inputChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  const wrapperKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => event.stopPropagation(),
    [],
  );

  const resetHandler = useCallback(() => {
    setFilterValue('');

    if (clearFilters) {
      clearFilters();
    }

    closeFilterDropdown();
  }, [clearFilters, closeFilterDropdown]);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        ref.current?.select();
      }, 100);
    }
  }, [visible]);

  useEffect(() => {
    const compoundKey: CompoundSelectedKey = {
      comparisonType,
      value: [filterValue],
    };

    setSelectedKeys([JSON.stringify(compoundKey)]);

    // setSelectedKeys CAN NOT be a dependency of this effect, otherwise it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonType, filterValue]);

  const comparisonTypeChangeHandler = useCallback(
    (value: string) => {
      setComparisonType(value);

      if (onComparisonTypeChanged) {
        onComparisonTypeChanged(columnKey, value);
      }
    },
    [onComparisonTypeChanged, columnKey],
  );

  return (
    <SearchPaneWrapper onKeyDown={wrapperKeyDownHandler}>
      {itemType !== 'boolean' && (
        <Space>
          <ComparisonSelection
            itemType={itemType}
            onChange={comparisonTypeChangeHandler}
            value={comparisonType}
          />
          <Input
            placeholder={`Search ${displayName}`}
            value={filterValue}
            onChange={inputChangeHandler}
            onPressEnter={handleSearch}
            ref={ref}
            type={itemType === 'number' ? 'number' : undefined}
          />
        </Space>
      )}
      <Space>
        {itemType === 'boolean' && (
          <ComparisonSelection
            itemType={itemType}
            onChange={comparisonTypeChangeHandler}
            value={comparisonType}
          />
        )}
        <Button
          type="primary"
          onClick={handleSearch}
          icon={searchIcon}
          disabled={filterValue.length === 0}
        >
          Search
        </Button>
        <Button onClick={resetHandler}>Reset</Button>
        <Button type="link" onClick={handleSearch}>
          Close
        </Button>
      </Space>
    </SearchPaneWrapper>
  );
};
