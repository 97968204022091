import React from 'react';
import { logoYapp } from '@ydistri/ds';
import { styled } from 'styled-components';

const FormImage = styled.img`
  height: 208px;

  @media (max-width: 1024px) {
    height: 104px;
  }
`;

export const NLILogo: React.FC = () => {
  return <FormImage src={logoYapp} alt="Y'distri" />;
};
