import { ConfigurationDataType, SkuType } from '@ydistri/api-sdk';
import { CSSProperties } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { COLORS } from '@ydistri/ds';
import type { SelectSize } from '@ydistri/ds';
import { EntityListConfiguration } from '../../../../apps/ydistri/src/app/screens/Configuration/ConfigurationCategories/SegmentPotentialDefinition/ConfigurationEntityLists/entityListLib';

export const getForecastApproachLabel = (
  input: string | number | undefined | null,
  type: 'full' | 'shortcut' = 'full',
): string => {
  if (!input) return '-';

  if (typeof input === 'string') {
    if (!['50', '75', '80', '90'].includes(input)) {
      throw new Error('Unknown forecasting approach');
    }
    input = parseInt(input);
  }

  if (type === 'full') {
    switch (input) {
      case 50:
        return 'Dynamic';
      case 75:
        return 'Balanced';
      case 80:
        return 'Conservative';
      case 90:
        return 'Super conservative';
      default:
        throw new Error('Unknown forecasting approach');
    }
  } else {
    switch (input) {
      case 50:
        return 'Dyn.';
      case 75:
        return 'Bal.';
      case 80:
        return 'Con.';
      case 90:
        return 'SCon.';
      default:
        throw new Error('Unknown forecasting approach');
    }
  }
};

export type SkuTypeColumn = SkuType | 'Target' | null;

export enum ConfigurationFieldStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  DEFAULT = 'default',
}

export interface StyledFieldProps {
  $width?: CSSProperties['width'];
  $size?: SelectSize;
  $color?: CSSProperties['color'];
}

export const skuColumnBackgroundColors: Record<SkuType | 'Target', CSSProperties['color']> = {
  [SkuType.FastMover]: COLORS.FASTMOVER_BACKGROUND,
  [SkuType.SlowMover]: COLORS.SLOWMOVER_BACKGROUND,
  [SkuType.DeadStock]: COLORS.DEADSTOCK_BACKGROUND,
  ['Target']: 'transparent',
};

export type SelectDataTypes =
  | ConfigurationDataType.SelectBoxForecastConfidence
  | ConfigurationDataType.SelectBoxMonthIdentifySkuType
  | ConfigurationDataType.SelectBoxMonthsForForecast
  | ConfigurationDataType.SelectBoxForcingLevelType;

export const configurationSelectOptions: Record<
  SelectDataTypes | 'historicForecastConfidence',
  DefaultOptionType[]
> = {
  [ConfigurationDataType.SelectBoxForecastConfidence]: [
    { value: 50, label: 'Dynamic' },
    { value: 75, label: 'Balanced' },
    { value: 80, label: 'Conservative' },
  ],
  historicForecastConfidence: [
    { value: 50, label: 'Dynamic' },
    { value: 75, label: 'Balanced' },
    { value: 80, label: 'Conservative' },
    { value: 90, label: 'Super conservative' },
  ],
  [ConfigurationDataType.SelectBoxMonthIdentifySkuType]: [
    { value: 6, label: '6' },
    { value: 12, label: '12' },
  ],
  [ConfigurationDataType.SelectBoxMonthsForForecast]: [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
  ],
  [ConfigurationDataType.SelectBoxForcingLevelType]: [
    { value: '100', label: 'No increase' },
    { value: '200', label: 'Slightly increase' },
    { value: '300', label: 'Significantly increase' },
  ],
};

export const configurationValueToString = (
  dataType: ConfigurationDataType,
  value: string,
  entityListId?: number,
): string => {
  switch (dataType) {
    case ConfigurationDataType.InputNumber:
      return value;
    case ConfigurationDataType.CheckBox:
      return value === '1' ? 'ON' : 'OFF';
    case ConfigurationDataType.SelectBoxForecastConfidence:
    case ConfigurationDataType.SelectBoxMonthIdentifySkuType:
    case ConfigurationDataType.SelectBoxMonthsForForecast:
    case ConfigurationDataType.SelectBoxForcingLevelType: {
      const option = configurationSelectOptions[dataType].find(option => option.value === value);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return (option?.label ?? '') as string;
    }
    case ConfigurationDataType.SelectBoxMinLayerList:
    case ConfigurationDataType.SelectBoxTargetList: {
      if (!entityListId) {
        throw new Error('No entityListId in configurationValueToString');
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return (JSON.parse(value ?? '[]') as EntityListConfiguration[]).find(
        x => x.EntityListId === entityListId,
      )?.IsEnabled === 1
        ? 'ON'
        : 'OFF';
    }
  }
};

export const getColorBasedOnStatusAndOverwrite = (
  status: ConfigurationFieldStatus,
  overwritten: boolean,
  parentException: boolean,
): CSSProperties['color'] => {
  switch (status) {
    case ConfigurationFieldStatus.SUCCESS:
      if (overwritten) return '#FFC107';
      if (parentException) return 'grey';
      return 'green';
    case ConfigurationFieldStatus.ERROR:
      return 'red';
    case ConfigurationFieldStatus.LOADING:
      return undefined;
    case ConfigurationFieldStatus.DEFAULT:
      if (overwritten) return '#FFC107';
      if (parentException) return 'grey';
      return undefined;
  }
};
