import { styled } from 'styled-components';
import { Input } from 'antd';
import React, {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ConfigurationFieldStatus,
  getColorBasedOnStatusAndOverwrite,
  StyledFieldProps,
} from '@ydistri/utils';
import {
  getIconBasedOnStatusAndOverwrite,
  OverwrittenIcon,
} from '../getIconBasedOnStatusAndOverwrite';
import { computeRemSize, STYLE } from '../styles/style';

const StyledInput = styled(Input)<StyledFieldProps>`
  width: ${p => p.$width ?? STYLE.DEFAULT_INPUT_WIDTH};
  border-color: ${p => (p.$color ? p.$color : undefined)};
  color: ${p => (p.$color ? p.$color : undefined)};
  appearance: textfield;
  font-size: ${computeRemSize(14)};

  &::placeholder {
    color: black;
  }

  &::placeholder,
  &:disabled {
    color: grey;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    font-size: 0.8rem;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export interface ConfigurationInputProps {
  disabled?: boolean;
  /** indicates that the selected value overwrites a value set somewhere higher in the hierarchy */
  overwritten?: boolean;
  overwrittenIcon?: OverwrittenIcon;
  parentException?: boolean;
  status: ConfigurationFieldStatus;
  value: number;
  onChange?: (value: string) => void;
  width?: CSSProperties['width'];
  placeholder?: string;
  customSuffix?: React.ReactNode;
  displayZero?: boolean;
  allowDecimals?: boolean;
  min?: number;
  max?: number;
}

export const ConfigurationInput: React.FC<ConfigurationInputProps> = ({
  disabled = false,
  overwritten = false,
  overwrittenIcon = OverwrittenIcon.SLIDERS,
  parentException = false,
  status = ConfigurationFieldStatus.DEFAULT,
  value,
  onChange = () => {},
  width,
  placeholder,
  customSuffix,
  displayZero = false,
  allowDecimals = true,
  min,
  max,
}) => {
  const [val, setVal] = useState<string>(value.toString());
  const [wasChanged, setWasChanged] = useState<boolean>(false);

  useEffect(() => {
    const stringifiedValue = value.toString();
    if (displayZero) {
      setVal(stringifiedValue === 'NaN' || stringifiedValue === '' ? '0' : stringifiedValue);
    } else {
      setVal(stringifiedValue);
    }
  }, [displayZero, value]);

  const color: CSSProperties['color'] = useMemo(
    () => getColorBasedOnStatusAndOverwrite(status, overwritten, parentException),
    [overwritten, parentException, status],
  );

  const suffix = useMemo(
    () => getIconBasedOnStatusAndOverwrite(status, overwritten, overwrittenIcon),
    [status, overwritten, overwrittenIcon],
  );

  const updateHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setWasChanged(true);
    setVal(e.target.value);
  }, []);

  const onChangeChecked = useCallback(
    (v: string) => {
      if (v === '') v = '0';
      if (min === undefined && max === undefined && allowDecimals) return onChange(v);
      let finalValue = allowDecimals ? parseFloat(v) : parseInt(v);

      if (min !== undefined) {
        if (finalValue < min) {
          finalValue = min;
        }
      }

      if (max !== undefined) {
        if (finalValue > max) {
          finalValue = max;
        }
      }

      setVal(finalValue.toString());
      onChange(finalValue.toString());
    },
    [allowDecimals, max, min, onChange],
  );

  const onBlurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (wasChanged) {
        onChangeChecked(e.currentTarget.value);
        setWasChanged(false);
      }
    },
    [onChangeChecked, wasChanged],
  );

  const onEnterHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (wasChanged) {
        onChangeChecked(e.currentTarget.value);
        setWasChanged(false);
      }
    },
    [onChangeChecked, wasChanged],
  );

  const parsedVal = !allowDecimals ? parseInt(val) : val;
  const v = val === '0' && placeholder ? '' : parsedVal;

  return (
    <StyledInput
      $color={color}
      suffix={suffix ?? customSuffix}
      type="number"
      disabled={status === ConfigurationFieldStatus.LOADING || disabled}
      value={v}
      onChange={updateHandler}
      onBlur={onBlurHandler}
      onPressEnter={onEnterHandler}
      $width={width}
      placeholder={placeholder}
      size="small"
      min={min}
      max={max}
    />
  );
};
