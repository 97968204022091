import { ConfigurationFieldStatus } from '@ydistri/utils';
import type { RequestStatusFlags } from '@reduxjs/toolkit/src/query/core/apiState';

export const rtkQueryPatchStatusToConfigurationFieldStatus = (
  patchConfigurationStatus: RequestStatusFlags,
): ConfigurationFieldStatus => {
  if (patchConfigurationStatus.isUninitialized) {
    return ConfigurationFieldStatus.DEFAULT;
  } else if (patchConfigurationStatus.isLoading) {
    return ConfigurationFieldStatus.LOADING;
  } else if (patchConfigurationStatus.isSuccess) {
    return ConfigurationFieldStatus.SUCCESS;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (patchConfigurationStatus.isError) {
    return ConfigurationFieldStatus.ERROR;
  }
  return ConfigurationFieldStatus.DEFAULT;
};
