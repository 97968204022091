import React, { PropsWithChildren, forwardRef } from 'react';
import { styled, css } from 'styled-components';

interface PanelProps extends PropsWithChildren {
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
  $grow?: number;
  $minHeight?: string;
  $height?: string;
  id?: string;
  $noAutoHeight?: boolean;
}

const PanelWrapper = styled.div<PanelProps>`
  display: flex;
  flex-direction: row;
  flex-grow: ${props => props.$grow ?? 1};
  ${props => {
    if (props.$minHeight) {
      return css`
        min-height: ${props.$minHeight};
      `;
    } else {
      if (!props.$noAutoHeight) {
        return css`
          min-height: 100%;
        `;
      }
    }
  }}
  ${props => {
    if (props.$height) {
      return css`
        height: ${props.$height};
      `;
    } else {
      if (!props.$noAutoHeight) {
        return css`
          height: 100%;
        `;
      }
    }
  }}

  align-items: flex-start;
`;

/**
 * Non-visual component that can be used to create a flex panel with row direction
 * and maximum available height if not specified by the ```height``` attribute or
 * suppressed by the ```noAutoHeight``` attribute.
 */
export const Panel: React.FC<PanelProps> = forwardRef(
  ({ $grow, children, $height, $minHeight, ...rest }, ref) => {
    return (
      <PanelWrapper
        $grow={$grow ?? 0}
        ref={ref}
        $height={$height}
        $minHeight={$minHeight}
        {...rest}
        data-type="Panel"
      >
        {children}
      </PanelWrapper>
    );
  },
);
Panel.displayName = 'Panel';
