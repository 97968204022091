import React, { ReactNode, useCallback } from 'react';
import { Modal, ModalProps } from 'antd';

export interface GenericConfirmationModalProps extends ModalProps {
  title: string;
  message?: string | ReactNode;
  confirmationButtonLabel?: string;
  onConfirmed: () => void;
  onCanceled: () => void;
  running?: boolean;
  width?: string | number;
}

/**
 * Prevents click event from bubbling up to the parent element.
 * Used to fix the Ant Design Modal behaviour.
 * @param event
 */
const voidClickHandler = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
};

export const GenericConfirmationModal: React.FC<GenericConfirmationModalProps> = ({
  title,
  message,
  confirmationButtonLabel = 'Continue',
  onConfirmed,
  onCanceled,
  running,
  width = 640,
  ...rest
}) => {
  const okHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onConfirmed();
    },
    [onConfirmed],
  );

  const cancelHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onCanceled();
    },
    [onCanceled],
  );

  return (
    <div onClick={voidClickHandler}>
      <Modal
        data-type="modal"
        open={true}
        title={title}
        closable={false}
        onCancel={cancelHandler}
        onOk={okHandler}
        okText={confirmationButtonLabel}
        confirmLoading={running}
        width={width}
        {...rest}
      >
        {message && <p>{message}</p>}
      </Modal>
    </div>
  );
};
