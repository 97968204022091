import { styled } from 'styled-components';
import React, { useCallback, useMemo, useState } from 'react';
import validatePassword from '../../../../../apps/ydistri/src/app/helpers/validatePassword';
import {
  COLORS,
  NLIArrowButton,
  NLIForm,
  NLILogo,
  NLIPageContent,
  NLIPageLayout,
} from '@ydistri/ds';

const Title = styled.h2`
  color: ${COLORS.REBRAND_ORANGE};
  margin: 0;
  font-size: 46px;
  font-weight: 500;
  font-family: 'Aeonik', Roboto, Helvetica, sans-serif;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  width: 500px;
  max-width: 500px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FormInfo = styled.div`
  font-size: 14px;
  font-style: italic;
`;

export const YellowInfo = styled(FormInfo)`
  color: yellow;
`;

export const OrangeInfo = styled(FormInfo)`
  color: orange;
`;

export const GreenInfo = styled(FormInfo)`
  color: green;
`;

interface NotLoggedInPageProps {
  title: string;
  infoTop?: React.ReactNode;
  infoBottom?: React.ReactNode;
  children?: React.ReactNode;
  inputs?: InputFieldType[];
  button?: string;
  onSubmit?: (values: Record<string, string>) => void;
  submitting: boolean;
  success?: boolean;
  hideFormOnSuccess?: boolean;
}

export type InputFieldValidationType =
  | {
      type: 'password';
    }
  | {
      type: 'must_match';
      field: string;
    };

export interface InputFieldType {
  title: string;
  type: 'text' | 'password';
  name: string;
  validation?: InputFieldValidationType;
}

const createEmptyValues = (inputs: InputFieldType[] | undefined): Record<string, string> =>
  (inputs ?? []).reduce((x: Record<string, string>, y) => {
    x[y.name] = '';
    return x;
  }, {});

export const NotLoggedInPage: React.FC<NotLoggedInPageProps> = ({
  title,
  inputs,
  children,
  infoTop,
  infoBottom,
  button,
  onSubmit,
  submitting,
  success = false,
  hideFormOnSuccess = false,
}) => {
  const [values, setValues] = useState<Record<string, string>>(createEmptyValues(inputs));

  const submitHandler = useCallback(() => onSubmit?.(values), [values, onSubmit]);

  const errors = useMemo(() => {
    const errors: Record<string, string> = {};

    (inputs ?? []).forEach(i => {
      if (i.validation?.type === 'password') {
        const pv = validatePassword(values[i.name]);
        errors[i.name] = pv !== true ? pv : '';
      } else if (i.validation?.type === 'must_match') {
        errors[i.name] =
          values[i.name] !== values[i.validation.field] ? 'passwords do not match' : '';
      } else {
        errors[i.name] = '';
      }
    });

    return errors;
  }, [values, inputs]);

  const hideForm = success && hideFormOnSuccess;

  return (
    <NLIPageLayout>
      <NLIPageContent>
        <NLILogo />
        <ContentColumn>
          <Title>{title}</Title>
          {infoTop ?? undefined}
          {!hideForm && inputs && <NLIForm inputs={inputs} errors={errors} setValues={setValues} />}
          {!hideForm && button && (
            <NLIArrowButton
              disabled={Object.values(errors).filter(e => e.length > 0).length > 0 || submitting}
              onClick={submitHandler}
              button={button}
            />
          )}
          {infoBottom}
          {children}
        </ContentColumn>
      </NLIPageContent>
    </NLIPageLayout>
  );
};
