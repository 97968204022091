/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  CalculationResponseCollectionResponse,
  Condition,
  ProductListItemComparisonInformationResponseDataResponse,
  ProductListItemsIdentificationResponseDataResponse,
  ProductListItemsRequest,
  ProductListRequest,
  ProductListResponseCollectionResponse,
  ProductListResponseDataResponse,
  ProductListUpdateRequest,
  ProductResponseCollectionResponse,
  Sorting,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class ProductLists<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags ProductLists
   * @name ProductListsList
   * @request GET:/ProductLists
   * @response `200` `ProductListResponseCollectionResponse` OK
   */
  productListsList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductListResponseCollectionResponse, any>({
      path: `/ProductLists`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name CreateCustomProductList
   * @request POST:/ProductLists
   * @response `200` `ProductListResponseDataResponse` OK
   */
  createCustomProductList = (data: ProductListRequest, params: RequestParams = {}) =>
    this.http.request<ProductListResponseDataResponse, any>({
      path: `/ProductLists`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name GetProducts
   * @request GET:/ProductLists/{id}/Products
   * @response `200` `ProductResponseCollectionResponse` OK
   */
  getProducts = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductResponseCollectionResponse, any>({
      path: `/ProductLists/${id}/Products`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name PutProductListItems
   * @request PUT:/ProductLists/{id}/Products
   * @response `200` `ProductListItemsIdentificationResponseDataResponse` OK
   */
  putProductListItems = (id: number, data: ProductListItemsRequest, params: RequestParams = {}) =>
    this.http.request<ProductListItemsIdentificationResponseDataResponse, any>({
      path: `/ProductLists/${id}/Products`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name PatchProductListItems
   * @request PATCH:/ProductLists/{id}/Products
   * @response `200` `ProductListItemsIdentificationResponseDataResponse` OK
   */
  patchProductListItems = (id: number, data: ProductListItemsRequest, params: RequestParams = {}) =>
    this.http.request<ProductListItemsIdentificationResponseDataResponse, any>({
      path: `/ProductLists/${id}/Products`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name DeleteProductListItems
   * @request DELETE:/ProductLists/{id}/Products
   * @response `200` `void` OK
   */
  deleteProductListItems = (
    id: number,
    data: ProductListItemsRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/ProductLists/${id}/Products`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name ProductsCompareCreate
   * @request POST:/ProductLists/{id}/Products/Compare
   * @response `200` `ProductListItemComparisonInformationResponseDataResponse` OK
   */
  productsCompareCreate = (id: number, data: ProductListItemsRequest, params: RequestParams = {}) =>
    this.http.request<ProductListItemComparisonInformationResponseDataResponse, any>({
      path: `/ProductLists/${id}/Products/Compare`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name UpdateCustomProductList
   * @request PATCH:/ProductLists/{id}
   * @response `200` `ProductListResponseDataResponse` OK
   */
  updateCustomProductList = (
    id: number,
    data: ProductListUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ProductListResponseDataResponse, any>({
      path: `/ProductLists/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name DeleteCustomProductList
   * @request DELETE:/ProductLists/{id}
   * @response `200` `void` OK
   */
  deleteCustomProductList = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ProductLists/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductLists
   * @name GetProductListCalculations
   * @request GET:/ProductLists/{id}/Calculations
   * @response `200` `CalculationResponseCollectionResponse` OK
   */
  getProductListCalculations = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationResponseCollectionResponse, any>({
      path: `/ProductLists/${id}/Calculations`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
