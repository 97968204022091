import React, { ChangeEvent, useCallback } from 'react';
import { styled } from 'styled-components';
import { InputFieldType } from '../NotLoggedInPage';

const FieldsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const FormRowTitleWrapper = styled.div`
  width: 358px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FormRowTitle = styled.div`
  font-size: 16px;
`;

const FormRowWarning = styled.div`
  color: orange;
  font-style: italic;
  font-size: 14px;
`;

const FormRowInput = styled.input`
  width: 358px;
  height: 40px;
  box-sizing: border-box;
  padding: 12px;
  font-family: Roboto, Helvetica, Arial, sans-serif; //'Segoe UI',
  border: 1px solid rgba(239, 235, 229, 0.2);
  color: rgba(239, 235, 229, 0.7);
  font-size: 16px;
  border-radius: 5px;

  /* version 2 */
  background-color: transparent;
  transition: 0.5s all;

  &:focus {
    outline: none;
    border: 1px solid #f29718;
  }
`;

interface NLIFormProps {
  inputs: InputFieldType[];
  errors: Record<string, string>;
  setValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export const NLIForm: React.FC<NLIFormProps> = ({ inputs, errors, setValues }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      setValues(p => ({ ...p, [e.target.name]: e.target.value })),
    [setValues],
  );

  return (
    <FieldsColumn>
      {inputs.map(i => {
        return (
          <FormRow key={i.name}>
            <FormRowTitleWrapper>
              <FormRowTitle>{i.title}</FormRowTitle>
              <FormRowWarning>{errors[i.name]}</FormRowWarning>
            </FormRowTitleWrapper>
            <FormRowInput name={i.name} onChange={handleChange} type={i.type} />
          </FormRow>
        );
      })}
    </FieldsColumn>
  );
};
