import { styled } from 'styled-components';

export const ASPLogo = styled.img`
  height: 2rem;
  margin: 0.5rem;
`;

export const ASPBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-family: Roboto, Helvetica, Arial, sans-serif; //'Segoe UI',
`;

export const ASPShadowBox = styled.div`
  width: 50%;
  padding: 2rem;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 5%);
  border-radius: 0.4em;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;
