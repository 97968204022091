/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return */
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { Table, TableProps } from 'antd';
import {
  COLORS,
  createParamsForInfiniteScroll,
  InfiniteScrollParams,
  STYLE,
  YColumnsType,
} from '@ydistri/ds';
import debounce from 'lodash.debounce';
import { TablePaginationConfig } from 'antd/lib';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { ColumnType } from 'antd/lib/table';
import { FilterComponent } from './FilterComponent';
import scrollIntoView from 'scroll-into-view';

export const ScrollTableWrapper = styled.div<{
  $id: string;
  $xSmall?: boolean;
  $border?: boolean;
  $minHeight?: CSSProperties['minHeight'];
  $height?: CSSProperties['height'];
  $maxHeight: CSSProperties['maxHeight'];
}>`
  #${props => props.$id} .ant-table-body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: ${props => props.$height ?? 'auto'};
    max-height: ${props => props.$maxHeight};
    ${props => (props.$minHeight ? `min-height: ${props.$minHeight};` : '')}
    border: ${props => (props.$border ? `1px solid ${COLORS.BORDER}` : '0')};

    ${STYLE.SCROLLBAR}
    .selected {
      background-color: ${props => props.theme.item.selected.backgroundColor};
    }

    ${p =>
      p.$xSmall
        ? `
          td.ant-table-cell:not(.ant-table-cell-scrollbar),
          th.ant-table-cell {
            padding: 0.25rem !important;
            padding-right: 0.75rem !important;
          }
        `
        : null}
  }
`;

export const tableScroll = { y: 'auto', scrollToFirstRowOnChange: true }; //scrollToFirstRowOnChange: false

const isRowMovement = (event: React.KeyboardEvent<HTMLDivElement>) => {
  const movementKeys = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp', 'Home', 'End'];
  return movementKeys.includes(event.key);
};

interface InfiniteScrollTableProps<T, U extends InfiniteScrollParams> extends TableProps<T> {
  id: string;
  searchValue?: string;
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['minHeight'];
  setParams: (params: U) => void;
  selectedRow?: T;
  onSelectRow?: (record: T) => void;
  totalRowCount?: number;
  xSmall?: boolean /* antd table already has property Size, but "small" is still too big */;
  addBorder?: boolean;
  showHeader?: boolean;
}

export const InfiniteScrollTable = <T extends object, U extends InfiniteScrollParams>({
  id,
  searchValue,
  height,
  minHeight,
  setParams,
  onChange,
  selectedRow,
  onSelectRow,
  columns,
  totalRowCount,
  xSmall,
  addBorder,
  showHeader = true,
  ...rest
}: InfiniteScrollTableProps<T, U>): React.ReactElement => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const getRowKey = useCallback(
    (record: T) => {
      if (typeof rest.rowKey === 'function') {
        return rest.rowKey(record);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rest.rowKey],
  );

  useEffect(() => {
    if (rest.dataSource && selectedRow) {
      const selectedRowKey = getRowKey(selectedRow);
      const i = rest.dataSource.findIndex(item => getRowKey(item) === selectedRowKey);
      // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect
      setSelectedRowIndex(i);
    }
  }, [getRowKey, rest.dataSource, selectedRow]);

  const selectRowCallback = useCallback(
    (record: T) => {
      if (onSelectRow) {
        onSelectRow(record);
        const rowKey = getRowKey(record);

        if (rowKey) {
          const rowElement = document.querySelector<HTMLTableRowElement>(
            `[data-row-key="${rowKey}"]`,
          );
          if (rowElement) {
            scrollIntoView(rowElement);
          }
        }
      }
    },
    [getRowKey, onSelectRow],
  );

  const keyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isRowMovement(event)) {
        return;
      }

      event.preventDefault();

      let newRowIndex = selectedRowIndex ?? 0;

      switch (event.key) {
        case 'Home':
          newRowIndex = 0;
          break;
        case 'ArrowDown':
          newRowIndex++;
          break;
        case 'ArrowUp':
          newRowIndex--;
          break;
        case 'PageDown':
          newRowIndex += 10;
          break;
        case 'PageUp':
          newRowIndex -= 10;
          break;
        case 'End':
          newRowIndex = (rest.dataSource ?? []).length - 1;
          break;
      }

      if (newRowIndex < 0 || newRowIndex >= (rest.dataSource ?? []).length) {
        return;
      } else {
        const record = rest.dataSource?.[newRowIndex];
        if (record) {
          selectRowCallback(record);
        }
      }
    },
    [selectedRowIndex, rest.dataSource, selectRowCallback],
  );

  const getColumnSearchProps = useCallback((column: ColumnType<T>): ColumnType<T> => {
    const tmpColumn: YColumnsType<T> = column;
    const itemType = tmpColumn.apiFilterType;

    if (tmpColumn.apiFilterType !== 'options') {
      return {
        ...column,
        filterDropdown: props => (
          <FilterComponent
            columnKey={column.key?.toString() ?? ''}
            filter={props}
            displayName={
              typeof column.title === 'string' ? column.title : (column.key?.toString() ?? '')
            }
            itemType={itemType ?? 'text'}
          />
        ),
      };
    } else {
      return column;
    }
  }, []);

  const filterEnabledColumns = useMemo(() => {
    if (columns) {
      return columns.map(column => {
        //const tmpColumn = column as YColumnsType<T>;
        if ('apiFilterable' in column) {
          if (column.apiFilterable) {
            return getColumnSearchProps(column);
          }
        }
        return column;
      });
    }
  }, [columns, getColumnSearchProps]);

  const loadMore = useCallback(() => {
    const skip = rest.dataSource?.length ?? 0;

    // @ts-expect-error Do not care
    setParams(p => ({ ...p, skip: skip }));
  }, [rest.dataSource?.length, setParams]);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    e => {
      let shouldLoadMore = true;
      if (totalRowCount !== undefined) {
        shouldLoadMore = totalRowCount > (rest.dataSource?.length ?? 0);
      }

      if (shouldLoadMore && e.target) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const node = e.target as HTMLDivElement | undefined;
        if (node) {
          const percentage = (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;
          // console.log(node.scrollTop, node.scrollHeight, node.clientHeight, percentage);
          if (percentage >= 75) {
            loadMore();
          }
        }
      }
    },
    [loadMore, rest.dataSource?.length, totalRowCount],
  );

  // ====================== SEARCH ======================

  const loadAfterSearch = useCallback(
    (search: string) => {
      // @ts-expect-error Do not care
      setParams(p => ({ ...p, search, skip: 0 }));
    },
    [setParams],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchValueChangeHandler = useCallback(
    debounce((p: string) => loadAfterSearch(p), 500),
    [],
  );

  useEffect(() => {
    // console.log('===useEffect searchValue', searchValue);
    if (searchValue !== undefined) {
      searchValueChangeHandler(searchValue);
    }
  }, [searchValue, searchValueChangeHandler]);

  const tableChangeHandler = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<T> | SorterResult<T>[],
      extra: TableCurrentDataSource<T>,
    ) => {
      // @ts-expect-error Do not care
      setParams(previousParams => {
        return createParamsForInfiniteScroll(filters, sorter, previousParams, columns);
      });

      if (onChange) {
        onChange(pagination, filters, sorter, extra);
      }
    },
    [columns, onChange, setParams],
  );

  return (
    <ScrollTableWrapper
      tabIndex={0}
      onKeyDown={onSelectRow ? keyDownHandler : undefined}
      $height={height}
      $maxHeight={height}
      $minHeight={minHeight}
      $id={id}
      $xSmall={xSmall}
      $border={addBorder}
    >
      <Table<T>
        id={id}
        size="small"
        pagination={false}
        scroll={tableScroll}
        onChange={tableChangeHandler}
        columns={filterEnabledColumns}
        onScroll={handleScroll}
        showHeader={showHeader}
        {...rest}
      />
    </ScrollTableWrapper>
  );
};
