import React, { useCallback } from 'react';
import { Row, Text } from '@ydistri/ds';
import { Divider } from 'antd';
import { addToast } from '@ydistri/utils';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { SYSTEM_ID_LABEL } from '../../../../../apps/ydistri/src/app/components/global/_constants';

interface EntityIdProps {
  id: number | string | null;
  systemId: number | null;
  code?: string | null;
}

interface EntityItemProps {
  label: string;
  value: number | string;
}

export const EntityItem: React.FC<EntityItemProps> = React.memo(({ label, value }) => {
  const dispatch = useDispatch();

  const copyToClipboard = useCallback(
    (text: string | null) => {
      if (text) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            dispatch(addToast({ message: `Value '${text}' copied to clipboard` }));
          })
          .catch(() => {
            dispatch(addToast({ message: `Failed to copy '${text}' to clipboard` }));
          });
      }
    },
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCopyToClipboard = useCallback(
    debounce(() => copyToClipboard(value.toString()), 300),
    [],
  );

  return (
    <Row $gap="0.2rem">
      <Text>{label}</Text>
      <Text onClick={debouncedCopyToClipboard}>{value}</Text>
    </Row>
  );
});

EntityItem.displayName = 'EntityItem';

/**
 * Common way to render an id and code of an entity like Category, Product, Store etc.
 * ID and Code are rendered in square brackets with an "ID: " and "Code: " prefixes.
 * @param id
 * @param code
 * @param systemId
 * @constructor
 */
export const EntityId: React.FC<EntityIdProps> = ({ id, code, systemId }) => {
  if (id || code || systemId) {
    return (
      <Row $gap="2px" $alignItems="center">
        <Text $type="light">[</Text>
        {id && <EntityItem label="Id:" value={id} />}
        {id && code && <Divider type="vertical" />}
        {code && <EntityItem label="Code:" value={code} />}
        {systemId && (code || id) && <Divider type="vertical" />}
        {systemId && <EntityItem label={`${SYSTEM_ID_LABEL}:`} value={systemId} />}
        <Text $type="light">]</Text>
      </Row>
    );
  }
};
