import { styled } from 'styled-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonProps, Input, InputRef, Modal, Typography } from 'antd';
import { computeRemSize } from '../styles/style';

const { Text } = Typography;

/**
 * If the value is not valid, return an error message.
 * @returns {string | undefined} - undefined if the value is valid, error message otherwise.
 */
export type InputValidator = (value: string) => string | undefined;

/* eslint-disable-next-line */
export interface GenericInputModalProps {
  title: string;
  placeholder: string;
  disabled?: boolean;
  onConfirmed: (value: string) => void;
  onClose: () => void;
  submitButtonLabel: string;
  requiredMessage: string;
  error?: boolean;
  width?: string | number;
  defaultValue?: string;
  validator?: InputValidator;
}

const InputWrapper = styled.div`
  padding: ${computeRemSize(24)} 0;
`;

export const GenericInputModal: React.FC<GenericInputModalProps> = ({
  title,
  placeholder,
  disabled = false,
  onConfirmed,
  onClose,
  submitButtonLabel,
  requiredMessage,
  error,
  width = 640,
  defaultValue,
  validator,
  ...rest
}) => {
  const [inputValue, setInputValue] = React.useState<string | undefined>(defaultValue);
  const [isEmptyInput, setIsEmptyInput] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const okButtonProps: ButtonProps = useMemo(() => {
    const isDisabled = disabled || isError;

    return {
      disabled: isDisabled,
    };
  }, [disabled, isError]);

  const cancelButtonProps: ButtonProps = useMemo(
    () => ({
      disabled,
    }),
    [disabled],
  );

  const closeHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClose();
    },
    [onClose],
  );

  const onOk = useCallback(() => {
    if (!inputValue || inputValue.length === 0) {
      setIsEmptyInput(true);
    } else {
      onConfirmed(inputValue);
    }
  }, [inputValue, onConfirmed]);

  const okHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onOk();
    },
    [onOk],
  );

  const inputChangeHandler = useCallback(
    (event: { target: { value: string | undefined } }) => {
      setIsEmptyInput(false);

      const newValue = event.target.value;
      setInputValue(newValue);
      if (validator && newValue) {
        const errorMessage = validator(newValue);
        if (errorMessage) {
          setIsError(true);
          setErrorMessage(errorMessage);
        } else {
          setIsError(false);
          setErrorMessage('');
        }
      }
    },
    [validator],
  );

  /**
   * If the Enter key was pressed, we treat it is as a Confirm button click
   */
  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onOk();
      }
    },
    [onOk],
  );

  /**
   * Autofocus the input element when mounted for better user experience
   * as there is only one input element where the user needs to write something.
   */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Modal
      data-type="modal"
      open={true}
      title={title}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      closable={false}
      onCancel={closeHandler}
      onOk={okHandler}
      okText={submitButtonLabel}
      width={width}
      {...rest}
    >
      <InputWrapper>
        <Input
          size="large"
          value={inputValue}
          onChange={inputChangeHandler}
          placeholder={placeholder}
          data-testid="GenericInputModalInput"
          disabled={disabled}
          status={isEmptyInput || error || isError ? 'error' : undefined}
          onKeyUp={onKeyUp}
          ref={inputRef}
        />
        {isEmptyInput && <Text type="danger">{requiredMessage}</Text>}
        {isError && errorMessage && <Text type="danger">{errorMessage}</Text>}
      </InputWrapper>
    </Modal>
  );
};
