/**
 * Compute the percentage of a value in a total
 * @param value
 * @param total
 * @param decimalCount
 */
export const computePercentage = (
  value: number,
  total: number,
  decimalCount: number = 0,
): number => {
  if (total === 0) return 100;

  return parseFloat(((value / total) * 100).toFixed(decimalCount));
};

/**
 * Compute the turnover. Result is in months in with 1 decimal
 *
 * Formula: 12 / (annualRevenueInStockPrice / availableSupplyInStockPrice)
 *
 * Both prices need to be in cost price
 *
 * @param {number} annualRevenueInStockPrice
 * @param {number} availableSupplyInStockPrice
 * @returns {number} - The computed turnover, in months, with 1 decimal
 */
export const computeTurnover = (
  annualRevenueInStockPrice: number,
  availableSupplyInStockPrice: number,
): number => {
  if (availableSupplyInStockPrice === 0) return 0;
  return Math.round((12 / (annualRevenueInStockPrice / availableSupplyInStockPrice)) * 10) / 10;
};

/**
 * Function to compute the annual revenue in cost price.
 *
 * @param {number} annualRevenueInSalePrice - The annual revenue in sale price.
 * @param {number} margin - The margin.
 * @returns {number} - The computed annual revenue in cost price.
 */
export const computeAnnualRevenueInStockPrice = (
  annualRevenueInSalePrice: number,
  margin: number,
): number => {
  return annualRevenueInSalePrice * (1 - margin);
};
