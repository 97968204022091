import { styled, css } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { CSSProperties, PropsWithChildren } from 'react';

const scrollbarMixin = css`
  &::-webkit-scrollbar {
    width: ${computeRemSize(10)};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: ${computeRemSize(10)};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export enum FlexDirections {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERSE = 'column-reverse',
  INHERIT = 'inherit',
  INITIAL = 'initial',
  REVERT = 'revert',
  UNSET = 'unset',
}

interface FlexProps extends PropsWithChildren {
  /** Direction of the items in the flex container */
  $flexDirection?: FlexDirections;
  /** Alignment of the items along the main axis, corresponds to the justify-content CSS */
  $justifyContent?: string;
  /** Alignment of the items along the cross axis, corresponds to the align-items CSS */
  $alignItems?: string;
  /** Alignment of the item on the cross axis, corresponds to the align-self CSS */
  $alignSelf?: CSSProperties['alignSelf'];
  /** Controls the space between flex items (not on the outer edges) */
  $gap?: string;
  /** Sets the space between the content and border in REM units */
  $padding?: number;
  /** Sets REM unit for padding, defaults to 0.5 */
  $unit?: number;
  /** Controls the growth of the flex item */
  $flexGrow?: CSSProperties['flexGrow'];
  /** Controls the wrapping of the flex items, defaults to 'wrap' */
  $flexWrap?: CSSProperties['flexWrap'];
  /** Width of the flex container */
  $width?: string;
  /** Minimum width of the flex container */
  $minWidth?: string;
  /** Minimum height of the flex container */
  $minHeight?: string;
  /** Maximum width of the flex container */
  $maxWidth?: string;
  /** Additional styles for the Flex container */
  style?: CSSProperties;
}

/**
 * Flex container
 */
export const Flex = styled.div<FlexProps>`
  display: flex;
  ${p => (p.$flexDirection ? `flex-direction: ${p.$flexDirection};` : '')}
  flex-wrap: ${p => (p.$flexWrap ? p.$flexWrap : 'wrap')};
  ${p => (p.$justifyContent ? `justify-content: ${p.$justifyContent};` : '')}
  ${p => (p.$alignItems ? `align-items: ${p.$alignItems};` : '')}
  ${p => (p.$alignSelf ? `align-self: ${p.$alignSelf};` : '')}
  ${p => (p.$gap ? `gap: ${p.$gap};` : '')}
  padding: ${p => (p.$padding ?? 0) * (p.$unit ?? 0.5)}rem;
  ${p => (p.$flexGrow ? `flex-grow: ${p.$flexGrow};` : '')}
  ${p => (p.$width ? `width: ${p.$width};` : '')}
  ${p => (p.$minWidth ? `min-width: ${p.$minWidth};` : '')}
  ${p => (p.$minHeight ? `min-height: ${p.$minHeight};` : '')}
  ${p => (p.$maxWidth ? `max-width: ${p.$maxWidth};` : '')}
`;

export const Row = styled(Flex)`
  flex-direction: ${FlexDirections.ROW};
`;

export const RowCentered = styled(Row)`
  align-items: center;
`;

export const RowWrap = styled(Row)`
  flex-wrap: wrap;
`;

export const Column = styled(Flex)`
  flex-direction: ${FlexDirections.COLUMN};
`;

interface ColumnScrollProps {
  $height: string;
}

export const ColumnScroll = styled(Column)<ColumnScrollProps>`
  flex-grow: 1;
  height: ${p => p.$height};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.6rem;
  flex-wrap: nowrap;

  ${scrollbarMixin}
`;
