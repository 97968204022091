import { styled } from 'styled-components';
import { COLORS } from '@ydistri/ds';

export const NLIPageLayout = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.REBRAND_DARK};
  display: flex;
  flex-direction: row;
  color: #aeafbb;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    font-size: 14px;
    color: ${COLORS.REBRAND_ORANGE};

    &:hover {
      opacity: 0.8;
    }
  }
`;
