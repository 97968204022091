import React from 'react';
import { ButtonProps } from 'antd/es/button/button';
import { Button, Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface YButtonProps extends ButtonProps {
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
}

/**
 * Button with a tooltip
 * @param tooltip Tooltip text
 * @param tooltipPlacement
 * @param rest Button properties
 * @constructor
 */
export const YButton: React.FC<YButtonProps> = React.memo(
  ({ tooltip, tooltipPlacement, ...rest }) => {
    if (tooltip) {
      return (
        <Popover content={tooltip} placement={tooltipPlacement}>
          <Button {...rest} />
        </Popover>
      );
    }

    return <Button {...rest} />;
  },
);
YButton.displayName = 'YButton';
