import { styled } from 'styled-components';
import { Table, TableProps } from 'antd';
import { STYLE } from '@ydistri/ds';
import React from 'react';

const ScrolledTableWrapper = styled.div<{ $id: string; $height: string }>`
  #${props => props.$id} .ant-table-body {
    overflow-y: scroll;
    height: ${props => props.$height};
    ${STYLE.SCROLLBAR}
  }
`;

export interface ScrolledTableProps<T> extends TableProps<T> {
  id: string;
  height?: string;
}

const tableScroll = { y: 'auto' };

/**
 * Table with necessary scroll attributes set
 * @param height - height of the table used for the height CSS property
 * @param id
 * @param rest
 * @constructor
 */
export const ScrolledTable = <T extends object>({
  height = '100px',
  id,
  ...rest
}: ScrolledTableProps<T>): React.ReactElement => {
  return (
    <ScrolledTableWrapper $height={height} $id={id} data-type="ScrolledTableWrapper">
      <Table<T> id={id} pagination={false} scroll={tableScroll} {...rest} />
    </ScrolledTableWrapper>
  );
};
