//unit will be multiplied by REM (=16px)
export const UNIT = 0.5; //=8px

export const COLUMN_COUNT = 12;
export const COLUMN_SIZE_IN_PERCENT = 100 / COLUMN_COUNT; //percent
export const MIN_COLUMN_SIZE = 75; //pixels

export const REM_SIZE = 16; //pixels
export const BASE_WIDTH = 1248; //pixels

/** Label for System ID (product, store etc.) */
export const SYSTEM_ID_LABEL = "Y'Id";

/** Label for SKU Id */
export const SKU_ID_LABEL = "Y'Sku";

// (1248 / 16) / 12 = 6.5
export const COLUMN_SIZE_IN_REM = BASE_WIDTH / REM_SIZE / COLUMN_COUNT; //rem

export enum YDColors {
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  GREY = 'grey',
}

export enum ColorLightness {
  DEFAULT = 'default',
  DARK = 'dark',
  LIGHT = 'light',
}

interface ColorObject {
  default: string;
  dark: string;
  light: string;
}

export const colors: Record<YDColors, ColorObject> = {
  green: {
    default: 'rgb(5, 154, 108)',
    dark: 'rgb(4,131,92)',
    light: '#dff1df',
  },
  orange: {
    default: '#f29718',
    dark: '#e08102',
    light: '#fff4e8',
  },
  red: {
    default: '#ed452e',
    dark: '#b92f1c',
    light: '#fff5f3',
  },
  grey: {
    default: '#929292',
    dark: '#6c6c6c',
    light: '#f3f3f3',
  },
};

export enum InputStates {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export const getColorByState = (
  state: InputStates,
  colorLightness: ColorLightness = ColorLightness.DEFAULT,
): string => {
  let colorObject: ColorObject = colors[YDColors.GREY];
  if (state === InputStates.SUCCESS) {
    colorObject = colors[YDColors.GREEN];
  } else if (state === InputStates.ERROR) {
    colorObject = colors[YDColors.RED];
  } else if (state === InputStates.WARNING) {
    colorObject = colors[YDColors.ORANGE];
  }

  if (colorLightness === ColorLightness.DARK) {
    return colorObject.dark;
  } else if (colorLightness === ColorLightness.LIGHT) {
    return colorObject.light;
  }

  return colorObject.default;
};

export const getColor = (
  clr: YDColors,
  colorLightness: ColorLightness = ColorLightness.DEFAULT,
): string => {
  const colorObject: ColorObject = colors[clr];

  if (colorLightness === ColorLightness.DARK) {
    return colorObject.dark;
  } else if (colorLightness === ColorLightness.LIGHT) {
    return colorObject.light;
  }

  return colorObject.default;
};
