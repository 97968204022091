import { styled, css } from 'styled-components';
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import React, { CSSProperties, ReactElement, useCallback, useMemo } from 'react';
import {
  ConfigurationFieldStatus,
  getColorBasedOnStatusAndOverwrite,
  StyledFieldProps,
  selectFilterHandler,
} from '@ydistri/utils';
import {
  getIconBasedOnStatusAndOverwrite,
  OverwrittenIcon,
} from '../getIconBasedOnStatusAndOverwrite';
import { computeRemSize, STYLE } from '../styles/style';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

export type SelectSize = 'small' | 'normal' | 'large';

const StyledSelect = styled(Select<unknown, DefaultOptionType>)<StyledFieldProps>`
  width: ${p => p.$width ?? STYLE.DEFAULT_INPUT_WIDTH};

  .ant-select-selector {
    border: ${computeRemSize(1)};
    border-color: ${p => (p.$color ? p.$color : undefined)} !important;
  }

  .ant-select-arrow {
    color: ${p => (p.$color ? p.$color : undefined)};
  }

  .ant-select-selection-item,
  .ant-select-selection-search {
    display: flex !important;
    align-items: center !important;
  }

  ${p =>
    p.$size === 'normal' &&
    css`
      min-height: ${computeRemSize(32)};

      .ant-select-selector {
        min-height: ${computeRemSize(32)} !important;
      }

      .ant-select-selection-item,
      .ant-select-selection-search input {
        font-size: 1 ${computeRemSize(14)} !important;
      }

      .ant-select-selection-overflow
        .ant-select-selection-overflow-item
        .ant-select-selection-item {
        min-height: ${computeRemSize(24)};
      }
    `}

  ${p =>
    p.$size === 'large' &&
    css`
      min-height: ${computeRemSize(40)};

      .ant-select-selector {
        min-height: ${computeRemSize(40)} !important;
      }

      .ant-select-selection-item,
      .ant-select-selection-search input {
        font-size: ${computeRemSize(16)} !important;
      }

      .ant-select-selection-overflow
        .ant-select-selection-overflow-item
        .ant-select-selection-item {
        min-height: ${computeRemSize(32)};
      }
    `}
`;

export interface ConfigurationSelectProps<T> {
  tagRender?: (props: CustomTagProps) => ReactElement;
  /** when true, the control cannot be interacted with */
  disabled?: boolean;
  loading?: boolean;
  /** indicates that the selected value overwrites a value set somewhere higher in the hierarchy */
  overwritten?: boolean;
  overwrittenIcon?: OverwrittenIcon;
  parentException?: boolean;
  status: ConfigurationFieldStatus;
  /** options for the select */
  options: DefaultOptionType[];
  /* selected value(s) */
  value?: T;
  /** whether to show the arrow icon pointing down to indicate options */
  showArrow?: boolean;
  onChange?: (value: T) => void;
  searchValue?: string;
  onSearch?: (value: string) => void;
  width?: CSSProperties['width'];
  size?: SelectSize;
  mode?: SelectProps<T>['mode'];
  disableOnLoading?: boolean;
  /** text to be displayed when no option is selected */
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
  autoClearSearchValue?: boolean;
}

export const ConfigurationSelect = <T,>({
  tagRender,
  disabled = false,
  loading = false,
  overwritten = false,
  overwrittenIcon = OverwrittenIcon.SLIDERS,
  parentException = false,
  status = ConfigurationFieldStatus.DEFAULT,
  options,
  value,
  showArrow = false,
  onChange = () => {},
  searchValue,
  onSearch = () => {},
  width,
  size = 'small',
  mode = undefined,
  disableOnLoading = true,
  placeholder = 'Select an option',
  popupMatchSelectWidth = true,
  autoClearSearchValue,
}: ConfigurationSelectProps<T>): React.ReactElement => {
  // const  = props;

  const color: CSSProperties['color'] = useMemo(
    () => getColorBasedOnStatusAndOverwrite(status, overwritten, parentException),
    [overwritten, parentException, status],
  );

  const suffix = useMemo(
    () => getIconBasedOnStatusAndOverwrite(status, overwritten, overwrittenIcon),
    [status, overwritten, overwrittenIcon],
  );

  const onChangeHandler: SelectProps['onChange'] = useCallback(
    (value: T) => onChange(value),
    [onChange],
  );

  const onSearchHandler: SelectProps['onSearch'] = useCallback(
    (value: string) => onSearch(value),
    [onSearch],
  );

  return (
    <StyledSelect
      tagRender={tagRender}
      mode={mode}
      maxTagCount={mode === 'multiple' ? 17 : undefined}
      $color={color}
      placeholder={placeholder}
      options={options}
      suffixIcon={suffix ?? (!showArrow ? null : undefined)}
      disabled={(status === ConfigurationFieldStatus.LOADING && disableOnLoading) || disabled}
      loading={loading}
      value={value}
      $width={width}
      $size={size}
      showSearch={true}
      filterOption={selectFilterHandler}
      onChange={onChangeHandler}
      onSearch={onSearchHandler}
      searchValue={searchValue}
      popupMatchSelectWidth={popupMatchSelectWidth}
      autoClearSearchValue={autoClearSearchValue}
    />
  );
};
