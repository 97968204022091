import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { COLORS, computeRemSize } from '@ydistri/ds';

const ARROW_AREA_HEIGHT = 0.25;

interface ArrowProps {
  width: CSSProperties['width'];
}

const ArrowArea = styled.div<ArrowProps>`
  position: relative;
  height: ${ARROW_AREA_HEIGHT}rem;
  margin-bottom: 1rem;
  width: ${p => p.width};
  min-width: ${p => p.width};

  border-left: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-right: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER};
`;

const ArrowLine = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(${ARROW_AREA_HEIGHT}rem);
  height: 1.3rem;
  width: ${computeRemSize(1)};
  background-color: ${COLORS.BORDER};
`;

const ArrowHead = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 0.5rem;
  width: 0.5rem;
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER};
  border-right: ${computeRemSize(1)} solid ${COLORS.BORDER};
  transform: translate(calc(-50% + ${computeRemSize(0.5)}), calc(0.7rem + ${ARROW_AREA_HEIGHT}rem))
    rotate(45deg);
`;

export const Arrow: React.FC<ArrowProps> = ({ width }) => {
  return (
    <ArrowArea width={width}>
      <ArrowLine />
      <ArrowHead />
    </ArrowArea>
  );
};
