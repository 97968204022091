import { Column } from '@ydistri/ds';
import React from 'react';

export const marginPopoverContent: React.ReactNode = (
  <Column>
    Computed from:
    <ul>
      <li>Sale price of sale transactions</li>
      <li>Cost price from inventory</li>
    </ul>
    Only non-sellout sales from last 90 days are considered
  </Column>
);
