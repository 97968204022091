import { styled } from 'styled-components';
import { Dropdown } from 'antd';

export const ResetButtonDropdown = styled(Dropdown)`
  display: flex;
  flex-grow: 0;
  justify-content: center;

  .ant-space-item {
    width: 200px !important;
    display: flex;
    align-items: center;
  }
`;
