import React, { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { Label, Row, Text } from '@ydistri/ds';

export interface ReadOnlyItemProps extends PropsWithChildren {
  /** Label to display, will be suffixed with a colon */
  label: string;
  /* Width of the label in CSS units */
  labelWidth?: string;
  labelStyle?: CSSProperties;
  /** Value as a string or string array (will be joined with a comma) */
  value?: string[] | React.ReactNode;
  /** Gap between label and value, 1rem is the default */
  spaceBetween?: string;
  /** If true, the label will not be bold */
  plainLabel?: boolean;
  /** Alignment of the items along the cross axis, corresponds to the align-items CSS */
  alignItems?: string;
  /** should the label and content wrap? Defaults to true */
  noWrap?: boolean;
}

/**
 * Displays a label and a value in a row. Value can be either a string or a ReactNode.
 * If value is not provided, children will be used instead. This allows to provide a more complex component
 * as a value.
 * @constructor
 * @param props
 */
export const ReadOnlyItem: React.FC<ReadOnlyItemProps> = ({
  label,
  value,
  spaceBetween = '1rem',
  plainLabel = false,
  children,
  labelWidth,
  alignItems = 'center',
  noWrap = false,
  labelStyle,
}) => {
  const labelComponent = useMemo(() => {
    const innerContent = `${label}:`;
    if (plainLabel) {
      return (
        <Text $width={labelWidth} style={labelStyle}>
          {innerContent}
        </Text>
      );
    } else {
      return (
        <Label $width={labelWidth} style={labelStyle}>
          {innerContent}
        </Label>
      );
    }
  }, [label, labelStyle, labelWidth, plainLabel]);

  const valueComponent = useMemo(() => {
    if (value) {
      if (typeof value === 'string' || Array.isArray(value)) {
        return <Text>{Array.isArray(value) ? value.join(', ') : value}</Text>;
      } else {
        return value;
      }
    } else {
      return children;
    }
  }, [children, value]);

  return (
    <Row
      $gap={spaceBetween}
      $alignItems={alignItems}
      data-type="ReadOnlyItem"
      $flexWrap={noWrap ? 'nowrap' : undefined}
    >
      {labelComponent}
      {valueComponent}
    </Row>
  );
};
