import { Avatar, Layout } from 'antd';
import { css, styled } from 'styled-components';
import { NavLink } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';
import { COLORS, computeRemSize, STYLE } from '@ydistri/ds';

const { Sider } = Layout;

export interface MenuItemProps {
  $isCollapsed: boolean;
  $isSelected: boolean;
  $isOpened: boolean;
  $isChild: boolean;
  $hasChild: boolean;
}

export const MenuSider = styled(Sider)`
  background: ${COLORS.MENU_DARK_BACKGROUND} !important;
  position: sticky !important;
  height: calc(100% - ${STYLE.COLLAPSOR_HEIGHT});
  padding-bottom: ${STYLE.COLLAPSOR_HEIGHT}; //collapsor height

  top: 0;
  z-index: 1;
  width: 100%;

  overflow-y: auto;

  ${STYLE.SCROLLBAR}

  border-inline-end: ${computeRemSize(1)} solid rgba(5, 5, 5, 0.06);

  & .ant-layout-sider-trigger {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${COLORS.MENU_DARK_BACKGROUND} !important;
    border-inline-end: ${computeRemSize(1)} solid rgba(5, 5, 5, 0.06);
    color: ${COLORS.WHITE};
    margin-top: -${computeRemSize(20)} !important;
    border-top: ${computeRemSize(1)} solid ${COLORS.BORDER_DARK};
    height: ${computeRemSize(60)} !important;
    font-size: ${computeRemSize(16)};
  }

  & .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  &.ant-layout-sider-collapsed {
    width: ${STYLE.LEFT_MENU_COLLAPSED_WIDTH} !important;
    flex: 0 0 ${STYLE.LEFT_MENU_COLLAPSED_WIDTH} !important;
    max-width: ${STYLE.LEFT_MENU_COLLAPSED_WIDTH} !important;
    min-width: ${STYLE.LEFT_MENU_COLLAPSED_WIDTH} !important;

    .ant-layout-sider-trigger {
      width: ${STYLE.LEFT_MENU_COLLAPSED_WIDTH} !important;
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  display: flex;
  align-items: center;
  padding: 0;
  transition: 0.5s all;
  width: ${computeRemSize(40)};
  height: ${computeRemSize(40)};
  border-radius: ${computeRemSize(STYLE.BORDER_RADIUS)};

  background-color: ${COLORS.PRIMARY_STEPS[1]};
  color: ${COLORS.PRIMARY};
`;

export const MenuLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${computeRemSize(60)};
  min-height: ${computeRemSize(60)};
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER_DARK};
`;

export const AvatarWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${computeRemSize(50)};
  padding: ${computeRemSize(5)} ${computeRemSize(10)};
  gap: 1rem;
  cursor: pointer;
  border-radius: ${computeRemSize(STYLE.BORDER_RADIUS)};
  transition: ${p => p.theme.item.transition};

  &:hover {
    background-color: ${COLORS.TABLE_HIGHLIGHT_GREY};
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

interface MenuItemWrapperProps {
  $childItem?: boolean;
}

export const MenuItemIconWrapper = styled.div<MenuItemWrapperProps>`
  font-size: ${computeRemSize(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  ${p => !p.$childItem && `color: ${COLORS.MENU_TEXT_COLOR ?? ''};`}
  transition: ${p => p.theme.item.transition};
`;

export interface MenuItemIconProps extends MenuItemWrapperProps, PropsWithChildren {}

export const MenuItemIcon: React.FC<MenuItemIconProps> = ({ $childItem, children }) => {
  return <MenuItemIconWrapper $childItem={$childItem}>{children}</MenuItemIconWrapper>;
};

export const MenuNavLink = styled(NavLink)<MenuItemProps>`
  width: 100%;
  height: ${p => (p.$isChild ? computeRemSize(60) : computeRemSize(90))};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  transition: ${p => p.theme.item.transition};

  border-right: 0.25rem solid transparent;
  border-left: 0.25rem solid transparent;

  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
  color: ${COLORS.MENU_TEXT_COLOR};
  ${p =>
    (p.$isChild || p.$isOpened) &&
    css`
      background-color: ${p.theme.item.hovered.backgroundColorDark};
      color: white;
    `}

  &:hover {
    ${p => !p.$isSelected && `background-color: ${p.theme.item.hovered.backgroundColorDark};`}
    color: white;

    ${MenuItemIconWrapper} {
      color: white;
    }
  }

  ${p =>
    p.$isChild &&
    css`
      margin-top: -${computeRemSize(1)};
      border-top: ${computeRemSize(1)} solid ${COLORS.BORDER_DARK};
      border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER_DARK};
    `}

  ${p =>
    p.$isSelected &&
    !p.$hasChild &&
    css`
      background-color: ${p.$isChild
        ? p.theme.item.selected.backgroundColorDark
        : p.theme.item.selected.backgroundColorDark};
      color: white;
      border-left-color: ${COLORS.PRIMARY};

      ${MenuItemIconWrapper} {
        color: white;
      }
    `}
`;

export const MenuTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.COLOR_DARK};
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER};

  height: ${computeRemSize(60)};
  min-height: ${computeRemSize(60)};
  //LayoutVerticalMain has padding, but we don't want it with header components - but not all pages have header and we still want padding on those
  margin: -${p => p.theme.globalPadding} -${p => p.theme.globalPadding}
    ${p => p.theme.globalPadding};
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 2 * ${p => p.theme.globalPadding});
`;

export const TopMenuPart = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
