import { styled } from 'styled-components';

export const NLIPageContent = styled.div`
  display: flex;
  flex-direction: row;
  color: #aeafbb;
  align-items: flex-start;
  gap: 56px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;
