import React, { CSSProperties, PropsWithChildren, useCallback, useMemo } from 'react';
import { Button, Popover } from 'antd';
import { styled, css } from 'styled-components';
import { StandardLonghandProperties, StandardShorthandProperties, Property } from 'csstype';
import { computeRemSize } from '../styles/style';
import { TooltipPlacement } from 'antd/lib/tooltip';

export interface ColorScheme {
  /** border color of the button */
  borderColor: StandardShorthandProperties['borderColor'];

  /** background color of the button */

  backgroundColor: StandardLonghandProperties['backgroundColor'];
  /** Fill color of the icon */

  fill: Property.Fill;
  /** Color of the icon */
  color: Property.Color;
}

export interface IconButtonColorScheme {
  normal: ColorScheme;
  highlighted?: ColorScheme;
}

export type IconButtonSize = 'xSmall' | 'small' | 'normal';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IconButton_ButtonSizes: Record<IconButtonSize, CSSProperties['width']> = {
  xSmall: computeRemSize(24),
  small: computeRemSize(32),
  normal: computeRemSize(40),
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IconButton_IconSizes: Record<IconButtonSize, CSSProperties['width']> = {
  xSmall: computeRemSize(14),
  small: computeRemSize(20),
  normal: computeRemSize(24),
};

export interface IconButtonProps extends PropsWithChildren {
  icon?: React.ReactNode;
  tooltip?: string;
  tooltipPosition?: TooltipPlacement;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;

  /** Size of the button. The icon must be sized accordingly independently */
  size?: IconButtonSize; //button size in pixels, currently width = height, default is 34px

  /** Make the button non-clickable if true */
  disabled?: boolean;

  /** Display a loading indicator, does not disable the button */
  loading?: boolean;
  colorScheme?: IconButtonColorScheme;
}

interface IconBodyProps {
  $size: IconButtonSize;
  $cursor?: StandardLonghandProperties['cursor'];
  $colorScheme?: IconButtonColorScheme;
  $disabled?: boolean;
}

/**
 * There is a bug that causes the tooltip to not disappear when the button is disabled.
 * So we need to mimic the disabled state by changing the color of the button.
 * and using cursor: not-allowed.
 * @see https://github.com/ant-design/ant-design/issues/34202
 */
const IconButtonBody = styled(Button)<IconBodyProps>`
  border-radius: ${computeRemSize(5)};
  transition: 0.1s all;
  cursor: ${({ $cursor, $disabled }) => ($disabled ? 'not-allowed' : ($cursor ?? 'pointer'))};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => IconButton_ButtonSizes[$size]} !important;
  height: ${({ $size }) => IconButton_ButtonSizes[$size]} !important;

  ${({ $colorScheme, $disabled }) => {
    if ($disabled) {
      return css`
        fill: #fdfdfd;
        color: #afafaf;
        border-color: rgba(29, 27, 37, 0.15);
        background-color: #fdfdfd;

        &:hover {
          fill: #efefef !important;
          color: #afafaf !important;
          border-color: #afafaf !important;
          background-color: #efefef !important;
        }
      `;
    }

    if ($colorScheme) {
      return css`
        fill: ${() => $colorScheme.normal.fill};
        color: ${() => $colorScheme.normal.color};
        border-color: ${() => $colorScheme.normal.borderColor};
        background-color: ${() => $colorScheme.normal.backgroundColor};

        ${() => {
          if ($colorScheme.highlighted) {
            return css`
              &:hover {
                fill: ${() => $colorScheme.highlighted?.fill} !important;
                color: ${() => $colorScheme.highlighted?.color} !important;
                border-color: ${() => $colorScheme.highlighted?.borderColor} !important;
                background-color: ${() => $colorScheme.highlighted?.backgroundColor} !important;
              }
            `;
          }
        }};
      `;
    } else {
      return css`
        border: ${computeRemSize(1)} solid rgba(29, 27, 37, 0.15);

        &:hover {
          fill: #f29718 !important;
          color: #f29718 !important;
        }
      `;
    }
  }};
`;

/**
 * Wrapper is needed to enforce the width of the button
 */
const IconButtonWrapper = styled.div<IconBodyProps>`
  display: inline-block;
  width: ${({ $size }) => IconButton_ButtonSizes[$size]};
`;

export const IconButton: React.FC<IconButtonProps> = React.memo(
  ({
    tooltip,
    tooltipPosition,
    size = 'normal',
    colorScheme,
    onClick,
    disabled,
    children,
    ...rest
  }) => {
    const clickHandler = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (!disabled) {
          if (onClick) {
            onClick(event);
          }
        }
      },
      [disabled, onClick],
    );

    const customButton = useMemo(() => {
      return (
        <IconButtonBody
          $size={size}
          $colorScheme={colorScheme}
          block //block is to fill parent at 100% and therefore use the width specified by the user
          onClick={clickHandler}
          $disabled={disabled}
          {...rest}
        >
          {children}
        </IconButtonBody>
      );
    }, [size, colorScheme, clickHandler, disabled, rest, children]);

    if (tooltip) {
      return (
        <Popover content={tooltip} placement={tooltipPosition}>
          <IconButtonWrapper $size={size}>{customButton}</IconButtonWrapper>
        </Popover>
      );
    } else {
      return customButton;
    }
  },
);

IconButton.displayName = 'IconButton';
