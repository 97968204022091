import { css, styled } from 'styled-components';
import { COLORS, computeRemSize, STYLE } from '../styles/style';
import { CSSProperties } from 'react';

export const SectionBase = styled.div`
  background-color: white;
  border-radius: ${computeRemSize(5)};
  //box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
  border: ${computeRemSize(1)} solid ${COLORS.BORDER};
  margin: ${STYLE.GLOBAL_PADDING};
  display: flex;
`;

export const SectionBaseCentered = styled(SectionBase)`
  align-items: center;
`;

export const SectionHeaderRow = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${computeRemSize(1)} solid ${COLORS.BORDER};
`;

export const SectionHeaderRowSpaceBetween = styled(SectionHeaderRow)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: transparent;
  padding-left: calc(2 * ${STYLE.GLOBAL_PADDING});

  //LayoutVerticalMain has padding, but we don't want it with header components - but not all pages have header and we still want padding on those
  margin: -${STYLE.GLOBAL_PADDING} -${STYLE.GLOBAL_PADDING} ${STYLE.GLOBAL_PADDING};
`;

export const SectionHeaderRowSpaceBetweenNoBorder = styled(SectionHeaderRowSpaceBetween)`
  margin: 0;
  padding-left: ${STYLE.GLOBAL_PADDING};
  padding-right: calc(
    ${STYLE.GLOBAL_PADDING} + 0.5rem
  ); //+0.5rem to be aligned with "Create calculation" icon above it
  padding-bottom: calc(${STYLE.GLOBAL_PADDING} / 2);
  padding-top: calc(${STYLE.GLOBAL_PADDING} / 2);
  border: 0;
`;

export const fixedSectionStyles = css`
  padding-top: ${computeRemSize(16)};
  flex-direction: column;
  flex-grow: 0;
  overflow-y: auto;
  ${STYLE.SCROLLBAR}
`;

export const SectionFixed = styled(SectionBase)<{ $width: CSSProperties['flexBasis'] }>`
  padding-top: ${computeRemSize(16)};
  flex-direction: column;
  flex-grow: 0;
  flex-basis: ${({ $width }) => $width};
  overflow-y: auto;
  ${STYLE.SCROLLBAR}
`;
